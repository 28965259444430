<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="lg" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[componentId, 'anti-shift']"
      @hidden="modalCancel"
      scrollable
    >

      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <template v-if="exists">
          <div class="history-button lock-container">
            <template v-if="isLockVisible">
              <div class="ml-1 mr-1">{{ $t('lock') }}</div>
              <b-form-checkbox :disabled="isLockReadOnly" switch v-model="staff.readOnly"/>
            </template>
            <b-button variant="secondary" size="sm" @click="state.historyShow = true" :disabled="contractStaffId == null || contractStaffId.includes('CONTRACT_STAFF_NEW_')">
              <font-awesome-icon :icon="['far', 'clock-rotate-left']"/>
              {{ $t('button.history') }}
            </b-button>
          </div>
          
        </template>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <template v-if="isAccessDenied">
        <div class="modal-message-overlay">
        <span class="grid-overlay">{{ 
          restrictedRequiredField != null
            ? $t('entity_selector.error.insufficient_permission_to_add_entity_with_reason', [$t('staff.title').toLowerCase(), restrictedRequiredField])
            : $t('entity_selector.error.insufficient_permission_to_add_entity', [$t('staff.title').toLowerCase()])
          }}</span>
        </div>
      </template>
      <template v-else>
        <div v-if="exists && !isGeneric" class="container pl-0">
          <b-row>
            <b-col cols="12" class="pr-0">
              <label class="mr-1" for="staffContract">{{ $t(`staff.field.contract`) }}</label>
              <button v-if="canAdd(permissionName, ['startDate', 'endDate','staffType','email','firstName','payAmount','payCurrency'])" id="CONTRACT_ADD" class="btn-action" :disabled="disableContractAddBtn" @click="contractStaffAdd"><font-awesome-icon :icon="['far', 'plus']"/>
                <b-popover
                    target="CONTRACT_ADD"
                    placement="top"
                    triggers="hover"
                    boundary="viewport"
                    :content="$t('staff.button.contract_add')">
                  </b-popover>
              </button>
              <button v-if="canDelete(permissionName)" id="CONTRACT_REMOVE" class="btn-action" :disabled="disableContractRemoveBtn" @click="contractStaffRemove"><font-awesome-icon :icon="['far', 'trash-can']"/>
                <b-popover
                  target="CONTRACT_REMOVE"
                  placement="top"
                  triggers="hover"
                  boundary="viewport"
                  :content="$t('staff.button.contract_remove')">
                </b-popover>
              </button>
              <b-form-group id="field-contract">
                <select id="staffContract" class="custom-select" v-model="contractStaffId"
                  v-validate="{ required: true }"
                  :data-vv-as="$t('staff.field.contract')"
                  data-vv-name="contractStaffId"
                  data-vv-delay="500"
                  @change="onStaffContractChanged"
                >
                  <template v-for="(opt, index) in contracts">
                    <option v-if="opt.uuId.includes('CONTRACT_STAFF_NEW_')" :value="opt.uuId" :key="index">{{ $t('staff.contract_new') }}</option>  
                    <option v-else :value="opt.uuId" :key="index">{{ getContractOptionLabel(opt, index) }}</option>
                  </template>
                </select>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showStaffTypeError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('staff.staffType') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <AvatarBanner 
          v-if="isAvatarBannerVisible" 
          v-model="avatarBanner" 
          :readOnly="isAvatarBannerReadOnly" 
          :baseAvatarIcon="['fad', 'user-tie']" @status="avatarBannerStatus"
        />
        <b-alert :variant="alertError? 'danger':'success'" dismissible :show="showError" @dismissed="dismissAlert">
          <font-awesome-icon :icon="alertError? ['fas', 'triangle-exclamation'] : ['far', 'check']"/>&nbsp;&nbsp;{{ alertMsg }}
          <ul :show="showErrorDetail" class="mb-0">
            <template v-for="(item, index) in alertMsgDetails">
              <li :key="index">{{ item }}</li>
            </template>
          </ul>
        </b-alert>

        <div class="container pl-0">
          <b-row>
            <template v-if="isGeneric">
              <b-col v-if="isFirstNameVisible" cols="12" md="8" class="pr-0">
                <b-form-group :label="$t(`staff.field.name`)" label-for="firstName">
                  <b-input-group>
                    <b-form-input id="firstName" type="text"
                      :data-vv-as="$t(`staff.field.name`)"
                      data-vv-name="staff.firstName"
                      data-vv-delay="500"
                      v-model="staff.firstName"
                      :readonly="isFirstNameReadOnly"
                      trim
                      :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'staff.firstName')">
                    </b-form-input>
                  </b-input-group>
                  <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
                    <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('staff.firstName') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <template v-if="customFieldMap['name'] != null">
                <b-col v-for="(field, index) in customFieldMap['name']" :key="'name'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>
          
              <b-col v-if="isIdentifierVisible" cols="12" md="4" class="pr-0">
                <b-form-group :label="$t('field.identifier')" label-for="identifier">
                  <b-input-group>
                    <b-form-input id="identifier" type="text"
                      :data-vv-as="$t('field.identifier')"
                      data-vv-name="staff.identifier"
                      :maxlength="maxIdentifierLength"
                      v-model="staff.identifier" 
                      :disabled="isIdentifierReadOnly"
                      trim>
                    </b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <template v-if="customFieldMap['email'] != null">
                <b-col v-for="(field, index) in customFieldMap['email']" :key="'email'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>

              <template v-if="customFieldMap['firstName'] != null">
                <b-col v-for="(field, index) in customFieldMap['firstName']" :key="'firstName'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>

              <template v-if="customFieldMap['lastName'] != null">
                <b-col v-for="(field, index) in customFieldMap['lastName']" :key="'lastName'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>
              
              <template v-if="customFieldMap['identifier'] != null">
                <b-col v-for="(field, index) in customFieldMap['identifier']" :key="'identifier'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>

              <template v-if="customFieldMap['default'] != null">
                <b-col v-for="(field, index) in customFieldMap['default']" :key="index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>

              <b-col v-if="isCompanyVisible" cols="12" class="pr-0">
                <b-form-group >
                  <label class="mr-1">{{ $t(`staff.field.company`) }}</label>
                  <button v-if="!isCompanyReadOnly" id="COMPANY_ADD" class="btn-action" @click="companySelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                    <b-popover
                      target="COMPANY_ADD"
                      placement="top"
                      triggers="hover"
                      :content="$t('staff.button.company_add')">
                    </b-popover>
                  </button>
                  <BadgeGroup v-model="company" :readOnly="isCompanyReadOnly">
                    <template v-slot:default="{ item, index }">
                      <Badge @badgeRemove="companyBadgeRemove(index)" @badgeClick="companyBadgeClick(item.uuId)"
                        :text="item.name" 
                        variant="primary" 
                        :pillable="!!item.pillable" :key="index" 
                        :readOnly="isCompanyReadOnly" />
                      </template>
                  </BadgeGroup>
                  <b-form-invalid-feedback class="mt-1 alert-danger form-field-alert" :class="{ 'd-block': showCompanyError }">
                    <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('staff.company') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <template v-if="customFieldMap['companies'] != null">
                <b-col v-for="(field, index) in customFieldMap['companies']" :key="'companies'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>
            </template>
            <template v-else>
              <b-col v-if="isEmailVisible" cols="12" md="8" class="pr-0">
                <b-form-group :label="$t(`staff.field.email`)" label-for="email">
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-input-group-text><font-awesome-icon :icon="['far', 'envelope']"/></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input id="email" type="text"
                      :data-vv-as="$t(`staff.field.email`)"
                      data-vv-name="staff.email"
                      data-vv-delay="500"
                      v-model="staff.email"
                      :readonly="isEmailReadOnly"
                      trim
                      :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'staff.email')">
                    </b-form-input>
                  </b-input-group>
                  <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showEmailError }">
                    <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('staff.email') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <template v-if="customFieldMap['email'] != null">
                <b-col v-for="(field, index) in customFieldMap['email']" :key="'email'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>

              <b-col v-if="isIdentifierVisible" cols="12" md="4" class="pr-0">
                <b-form-group :label="$t('field.identifier')" label-for="identifier">
                  <b-input-group>
                    <b-form-input id="identifier" type="text"
                      :data-vv-as="$t('field.identifier')"
                      data-vv-name="staff.identifier"
                      :maxlength="maxIdentifierLength"
                      v-model="staff.identifier" 
                      :readonly="isIdentifierReadOnly"
                      trim>
                    </b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <template v-if="customFieldMap['identifier'] != null">
                <b-col v-for="(field, index) in customFieldMap['identifier']" :key="'identifier'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>

              <template v-if="customFieldMap['default'] != null">
                <b-col v-for="(field, index) in customFieldMap['default']" :key="index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>
              
              <b-col v-if="isFirstNameVisible" cols="12" class="pr-0">
                <b-form-group :label="$t(`staff.field.firstName`)" label-for="firstName">
                  <b-input-group>
                    <b-form-input id="firstName" type="text"
                    :data-vv-as="$t(`staff.field.firstName`)"
                      data-vv-name="staff.firstName"
                      data-vv-delay="500"
                      v-model="staff.firstName"
                      :readonly="isFirstNameReadOnly"
                      trim
                      :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'staff.firstName')">
                    </b-form-input>
                  </b-input-group>
                  <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
                    <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('staff.firstName') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <template v-if="customFieldMap['firstName'] != null">
                <b-col v-for="(field, index) in customFieldMap['firstName']" :key="'firstName'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>

              <b-col v-if="isLastNameVisible" cols="12" class="pr-0">
                <b-form-group :label="$t(`staff.field.lastName`)" label-for="lastName">
                  <b-input-group>
                    <b-form-input id="lastName" type="text"
                      :data-vv-as="$t(`staff.field.lastName`)"
                      data-vv-name="staff.lastName"
                      data-vv-delay="500"
                      v-model="staff.lastName"
                      :readonly="isLastNameReadOnly"
                      trim
                      :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'staff.lastName')">
                    </b-form-input>
                  </b-input-group>
                  <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showFamilyNameError }">
                    <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('staff.lastName') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <template v-if="customFieldMap['lastName'] != null">
                <b-col v-for="(field, index) in customFieldMap['lastName']" :key="'lastName'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>

              <template v-if="customFieldMap['name'] != null">
                <b-col v-for="(field, index) in customFieldMap['name']" :key="'name'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>
              
              <b-col v-if="isCompanyVisible" cols="12" class="pr-0">
                <b-form-group >
                  <label class="mr-1">{{ $t(`staff.field.company`) }}</label>
                  <button v-if="!isCompanyReadOnly" id="COMPANY_ADD" class="btn-action" @click="companySelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                    <b-popover
                      target="COMPANY_ADD"
                      placement="top"
                      triggers="hover"
                      :content="$t('staff.button.company_add')">
                    </b-popover>
                  </button>
                  <BadgeGroup v-model="company" :readOnly="isCompanyReadOnly">
                    <template v-slot:default="{ item, index }">
                      <Badge @badgeRemove="companyBadgeRemove(index)" @badgeClick="companyBadgeClick(item.uuId)"
                        :text="item.name" 
                        variant="primary" 
                        :pillable="!!item.pillable" :key="index" 
                        :readOnly="isCompanyReadOnly" />
                      </template>
                  </BadgeGroup>
                  <b-form-invalid-feedback class="mt-1 alert-danger form-field-alert" :class="{ 'd-block': showCompanyError }">
                    <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('staff.company') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <template v-if="customFieldMap['companies'] != null">
                <b-col v-for="(field, index) in customFieldMap['companies']" :key="'companies'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>
            </template>
          
            <b-col cols="12" class="pr-0" if="isDepartmentVisible">
              <b-form-group>
                <label class="mr-1">{{ $t(`staff.field.departments`) }}</label>
                <button :disabled="company.length === 0" v-if="!isDepartmentReadOnly" id="DEPARTMENT_ADD" class="btn-action" @click="depSelectorToggle"><font-awesome-icon :class="company.length === 0 ? 'greyed' : ''" :icon="['far', 'plus']"/>
                  <b-popover
                    target="DEPARTMENT_ADD"
                    placement="top"
                    triggers="hover"
                    :content="$t('staff.button.department_add')">
                  </b-popover>
                </button>
                <BadgeGroup v-model="departments" :readOnly="isDepartmentReadOnly">
                  <template v-slot:default="{ item, index }">
                    <Badge @badgeRemove="depBadgeRemove(index)" @badgeClick="depBadgeClick(item.uuId)"
                      :text="`${item.name}`" 
                      variant="primary"
                      :pillable="!!item.pillable" :key="index"
                      :readOnly="isDepartmentReadOnly" />
                    </template>
                </BadgeGroup>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['departments'] != null">
              <b-col v-for="(field, index) in customFieldMap['departments']" :key="'departments'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <template v-if="!isGeneric">
              <b-col v-if="isPositionVisible" cols="12" md="6" class="pr-0">
                <b-form-group :label="$t(`staff.field.position`)" label-for="position">
                  <b-input-group>
                    <b-form-input id="position" type="text"
                      :data-vv-as="$t(`staff.field.position`)"
                      data-vv-name="staff.position"
                      data-vv-delay="500"
                      v-model="staff.position"
                      :readonly="isPositionReadOnly"
                      trim>
                    </b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <template v-if="customFieldMap['position'] != null">
                <b-col v-for="(field, index) in customFieldMap['position']" :key="'position'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>
              
              <b-col v-if="isStaffTypeVisible" cols="12" md="6" class="pr-0">
                <b-form-group id="field-type" :label="$t('staff.field.type')" label-for="staffType">
                  <select id="staffType" class="custom-select" v-model="staff.staffType" :disabled="isStaffTypeReadOnly"
                    v-validate="{ required: true }"
                    :data-vv-as="$t('staff.field.type')"
                    data-vv-name="staff.staffType"
                    data-vv-delay="500"
                  >
                    <template v-for="(opt, index) in typeOptions">
                      <option :value="opt.value" :key="index" :style="{ display: opt.num < 0? 'none': 'block' }">{{ opt.text }}</option>
                    </template>
                  </select>
                  <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showStaffTypeError }">
                    <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('staff.staffType') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <template v-if="customFieldMap['type'] != null">
                <b-col v-for="(field, index) in customFieldMap['type']" :key="'type'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>

              <b-col v-if="isLocationVisible" cols="12" class="pr-0">
                <b-form-group>
                  <label class="mr-1">{{ $t(`staff.field.location`) }}</label>
                  <button v-if="!isLocationReadOnly" id="LOCATION_ADD" class="btn-action" @click="locSelectorOpen"><font-awesome-icon :icon="['far', 'plus']"/>
                    <b-popover
                      target="LOCATION_ADD"
                      placement="top"
                      triggers="hover"
                      :content="$t('staff.button.location_add')">
                    </b-popover>
                  </button>
                  <BadgeGroup v-model="location" :readOnly="isLocationReadOnly">
                    <template v-slot:default="{ item, index }">
                      <Badge @badgeRemove="locationBadgeRemove(index)" @badgeClick="locationBadgeClick(item.uuId)"
                        :text="item.name" 
                        variant="primary" 
                        :pillable="!!item.pillable" :key="index"
                        :readOnly="isLocationReadOnly" />
                      </template>
                  </BadgeGroup>
                  <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showLocationError }">
                    <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('location') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <template v-if="customFieldMap['locations'] != null">
                <b-col v-for="(field, index) in customFieldMap['locations']" :key="'locations'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>
            </template>
            <template v-else>
              <template v-if="customFieldMap['position'] != null">
                <b-col v-for="(field, index) in customFieldMap['position']" :key="'position'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>
              
              <b-col v-if="isLocationVisible" cols="12" md="6" class="pr-0">
                <b-form-group>
                  <label class="mr-1">{{ $t(`staff.field.location`) }}</label>
                  <button v-if="!isLocationReadOnly" id="LOCATION_ADD" class="btn-action" @click="locSelectorOpen"><font-awesome-icon :icon="['far', 'plus']"/>
                    <b-popover
                      target="LOCATION_ADD"
                      placement="top"
                      triggers="hover"
                      :content="$t('staff.button.location_add')">
                    </b-popover>
                  </button>
                  <BadgeGroup v-model="location" :readOnly="isLocationReadOnly">
                    <template v-slot:default="{ item, index }">
                      <Badge @badgeRemove="locationBadgeRemove(index)" @badgeClick="canView('LOCATION') ? locationBadgeClick(item.uuId) : ''"
                        :text="item.name" 
                        variant="primary" 
                        :pillable="!!item.pillable" :key="index" 
                        :readOnly="isLocationReadOnly" />
                      </template>
                  </BadgeGroup>
                  <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showLocationError }">
                    <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('location') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              
              <template v-if="customFieldMap['locations'] != null">
                <b-col v-for="(field, index) in customFieldMap['locations']" :key="'locations'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>

              <b-col v-if="isStaffTypeVisible" cols="12" md="6" class="pr-0">
                <b-form-group id="field-type" :label="$t('staff.field.type')" label-for="staffType" style="padding-top: 6px;">
                  <select id="staffType" class="custom-select" v-model="staff.staffType" :disabled="isStaffTypeReadOnly"
                    v-validate="{ required: true }"
                    :data-vv-as="$t('staff.field.type')"
                    data-vv-name="staff.staffType"
                    data-vv-delay="500">
                    <template v-for="(opt, index) in typeOptions">
                      <option :value="opt.value" :key="index" :style="{ display: opt.num < 0? 'none': 'block' }">{{ opt.text }}</option>
                    </template>
                  </select>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showStaffTypeError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('staff.staffType') }}
                </b-form-invalid-feedback>
              </b-form-group>
              </b-col>

              <template v-if="customFieldMap['type'] != null">
                <b-col v-for="(field, index) in customFieldMap['type']" :key="'type'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>
            </template>

            <template v-if="!isGeneric">
              <b-col cols="12" class="pr-0" v-if="isPhonesVisible">
                <b-form-group class="mb-0">
                  <label class="mr-1">{{ $t(`staff.field.phones`) }}</label>
                  <b-row v-for="(phone, index) in staff.phones" v-bind:key="index">
                    <b-col lg>
                      <b-form-group>
                        <b-input-group>
                          <b-input-group-prepend v-if="!(isPhonesReadOnly && phone.kind == null)">
                            <b-form-select v-model="phone.kind" :options="optionPhoneNrs" :disabled="isPhonesReadOnly">
                                <template slot="first">
                                  <option :value="null" disabled>{{ $t('staff.select_phone_type') }}</option>
                                </template>
                            </b-form-select>
                          </b-input-group-prepend>
                          <b-form-input type="text"
                            v-model="phone.data"
                            :readonly="isPhonesReadOnly"
                            trim></b-form-input>
                          <template v-if="!isPhonesReadOnly">
                            <b-input-group-append>
                              <b-button :id="`PHONE_BTN_ADD_${id}_${index}`" variant="secondary" @click="fieldItemAdd('phones', index, {kind: null, data: null})"><font-awesome-icon :icon="['far', 'plus']"/></b-button>
                              <b-popover :target="`PHONE_BTN_ADD_${id}_${index}`" triggers="hover" placement="top">
                                {{ $t('button.add') }}
                              </b-popover>
                            </b-input-group-append>
                            <b-input-group-append>
                              <b-button :id="`PHONE_BTN_REMOVE_${id}_${index}`" variant="secondary" @click="fieldItemRemove('phones', index, {kind: null, data: null})"><font-awesome-icon :icon="['far', 'trash-can']"/></b-button>
                              <b-popover :target="`PHONE_BTN_REMOVE_${id}_${index}`" triggers="hover" placement="top">
                                {{ $t('button.remove') }}
                              </b-popover>
                            </b-input-group-append>
                          </template>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>

              <template v-if="customFieldMap['phones'] != null">
                <b-col v-for="(field, index) in customFieldMap['phones']" :key="'phones'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>
              
              <b-col cols="12" class="pr-0" v-if="isWebsitesVisible">
                <b-form-group class="mb-0">
                  <label class="mr-1">{{ $t(`staff.field.websites`) }}</label>
                  <b-row v-for="(website, index) in staff.websites" v-bind:key="index">
                    <b-col lg>
                      <b-form-group>
                        <b-input-group>
                          <b-form-input type="text" :id='`website`+index' v-model="staff.websites[index]" :readonly="isWebsitesReadOnly" trim></b-form-input>
                          <template v-if="!isWebsitesReadOnly">
                            <b-input-group-append>
                              <b-button :id="`WEBSITE_BTN_ADD_${id}_${index}`" variant="secondary" @click="fieldItemAdd('websites', index, null)"><font-awesome-icon :icon="['far', 'plus']"/></b-button>
                              <b-popover :target="`WEBSITE_BTN_ADD_${id}_${index}`" triggers="hover" placement="top">
                                {{ $t('button.add') }}
                              </b-popover>
                            </b-input-group-append>
                            <b-input-group-append>
                              <b-button :id="`WEBSITE_BTN_REMOVE_${id}_${index}`" variant="secondary" @click="fieldItemRemove('websites', index, null)"><font-awesome-icon :icon="['far', 'trash-can']"/></b-button>
                              <b-popover :target="`WEBSITE_BTN_REMOVE_${id}_${index}`" triggers="hover" placement="top">
                                {{ $t('button.remove') }}
                              </b-popover>
                            </b-input-group-append>
                          </template>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>

              <template v-if="customFieldMap['websites'] != null">
                <b-col v-for="(field, index) in customFieldMap['websites']" :key="'websites'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>

              <b-col cols="12" class="pr-0" v-if="isSocialsVisible">
                <b-form-group class="mb-0">
                  <label class="mr-1">{{ $t(`staff.field.socials`) }}</label>
                  <b-row v-for="(social, index) in staff.socials" v-bind:key="index">
                    <b-col lg>
                      <b-form-group>
                        <b-input-group>
                          <b-input-group-prepend v-if="!(isSocialsReadOnly && social.kind ==null)">
                            <b-form-select v-model="social.kind" :options="optionSocials"
                              :disabled="isSocialsReadOnly"
                            >
                                <template slot="first">
                                  <option :value="null" disabled>{{ $t('staff.select_socialnet_type') }}</option>
                                </template>
                            </b-form-select>
                          </b-input-group-prepend>
                          <b-form-input type="text"
                            v-model="social.data"
                            :readonly="isSocialsReadOnly"
                            trim></b-form-input>
                          <template v-if="!isSocialsReadOnly">
                            <b-input-group-append>
                              <b-button :id="`SOCIAL_BTN_ADD_${id}_${index}`" variant="secondary" @click="fieldItemAdd('socials', index, {kind: null, data: null})"><font-awesome-icon :icon="['far', 'plus']"/></b-button>
                              <b-popover :target="`SOCIAL_BTN_ADD_${id}_${index}`" triggers="hover" placement="top">
                                {{ $t('button.add') }}
                              </b-popover>
                            </b-input-group-append>
                            <b-input-group-append>
                              <b-button :id="`SOCIAL_BTN_REMOVE_${id}_${index}`" variant="secondary" @click="fieldItemRemove('socials', index, {kind: null, data: null})"><font-awesome-icon :icon="['far', 'trash-can']"/></b-button>
                              <b-popover :target="`SOCIAL_BTN_REMOVE_${id}_${index}`" triggers="hover" placement="top">
                                {{ $t('button.remove') }}
                              </b-popover>
                            </b-input-group-append>
                          </template>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>

              <template v-if="customFieldMap['socials'] != null">
                <b-col v-for="(field, index) in customFieldMap['socials']" :key="'socials'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>
              
              <b-col v-if="isStartDateVisible" cols="12" md="6" class="pr-0">
                <b-form-group :label="$t('project.field.scheduleStart')" label-for="startDate">
                  <b-form-datepicker id="startDate" v-model="staff.startDate" class="mb-2"
                    :max="staff.endDate"
                    value-as-date
                    today-button
                    reset-button
                    close-button
                    hide-header
                    :label-today-button="$t('date.today')"
                    :label-reset-button="$t('date.reset')"
                    :label-close-button="$t('date.close')"
                    today-button-variant="primary"
                    reset-button-variant="danger" 
                    close-button-variant="secondary"
                    :readonly="isStartDateReadOnly"
                  >
                    <template v-slot:button-content="{ }">
                      <font-awesome-icon :icon="['far', 'calendar-days']" />
                    </template>
                  </b-form-datepicker>
                </b-form-group>
              </b-col>

              <template v-if="customFieldMap['startDate'] != null">
                <b-col v-for="(field, index) in customFieldMap['startDate']" :key="'startDate'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>
              
              <b-col v-if="isEndDateVisible" cols="12" md="6" class="pr-0">
                <b-form-group :label="$t('project.field.scheduleFinish')" label-for="endDate">
                  <b-form-datepicker id="endDate" v-model="staff.endDate" class="mb-2"
                    :min="staff.startDate"
                    value-as-date
                    today-button
                    reset-button
                    close-button
                    hide-header
                    :label-today-button="$t('date.today')"
                    :label-reset-button="$t('date.reset')"
                    :label-close-button="$t('date.close')"
                    today-button-variant="primary"
                    reset-button-variant="danger" 
                    close-button-variant="secondary"
                    :readonly="isEndDateReadOnly"
                  >
                    <template v-slot:button-content="{ }">
                      <font-awesome-icon :icon="['far', 'calendar-days']" />
                    </template>
                  </b-form-datepicker>
                </b-form-group>
              </b-col>

              <template v-if="customFieldMap['endDate'] != null">
                <b-col v-for="(field, index) in customFieldMap['endDate']" :key="'endDate'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>
            </template>
            <template v-else>
              <template v-if="customFieldMap['phones'] != null">
                <b-col v-for="(field, index) in customFieldMap['phones']" :key="'phones'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>

              <template v-if="customFieldMap['websites'] != null">
                <b-col v-for="(field, index) in customFieldMap['websites']" :key="'websites'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>

              <template v-if="customFieldMap['socials'] != null">
                <b-col v-for="(field, index) in customFieldMap['socials']" :key="'socials'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>

              <template v-if="customFieldMap['startDate'] != null">
                <b-col v-for="(field, index) in customFieldMap['startDate']" :key="'startDate'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>

              <template v-if="customFieldMap['endDate'] != null">
                <b-col v-for="(field, index) in customFieldMap['endDate']" :key="'endDate'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>
            </template>


            <b-col :cols="12" class="pr-0" v-if="isSkillVisible">
              <b-form-group>
                <label class="mr-1">{{ $t(`staff.field.skills`) }}</label>
                <button v-if="!isSkillReadOnly" id="SKILL_ADD" class="btn-action" @click="skillSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                  <b-popover
                    target="SKILL_ADD"
                    placement="top"
                    triggers="hover"
                    :content="$t('staff.button.skill_add')">
                  </b-popover>
                </button>
                <BadgeGroup v-model="skills" :readOnly="isSkillReadOnly">
                  <template v-slot:default="{ item, index }">
                    <Badge @badgeRemove="skillBadgeRemove(index)" @badgeClick="skillBadgeClick(item.uuId)"
                      :text="`${item.name} (${item.level})`" 
                      variant="primary" 
                      :pillable="!!item.pillable" :key="index"
                      :readOnly="isSkillReadOnly" />
                    </template>
                </BadgeGroup>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['skills'] != null">
              <b-col v-for="(field, index) in customFieldMap['skills']" :key="'skills'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
            
            <b-col cols="12" class="pr-0" v-if="isResourceVisible">
              <b-form-group>
                <label class="mr-1">{{ $t(`staff.field.resources`) }}</label>
                <button v-if="!isResourceReadOnly" id="RESOURCE_ADD" class="btn-action" @click="resourceSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                  <b-popover
                    target="RESOURCE_ADD"
                    placement="top"
                    triggers="hover"
                    :content="$t('staff.button.resource_add')">
                  </b-popover>
                </button>
                <BadgeGroup v-model="resources" :readOnly="isResourceReadOnly">
                  <template v-slot:default="{ item, index }">
                    <Badge @badgeRemove="resourceBadgeRemove(index)" @badgeClick="resourceBadgeClick(item.uuId)"
                      :text="`${item.name} (${item.quantity > 1? item.quantity + ' x ' :''}${item.utilization? item.utilization * 100 : 100}%)`" 
                      variant="primary" 
                      :pillable="!!item.pillable" :key="index"
                      :readOnly="isResourceReadOnly" />
                    </template>
                </BadgeGroup>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['resources'] != null">
              <b-col v-for="(field, index) in customFieldMap['resources']" :key="'resources'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
            
            <b-col cols="12" :md="isColorVisible? 8 : 12" class="pr-0" v-if="isPayAmountVisible">
              <b-form-group :label="$t(`staff.field.payAmount`)" label-for="payAmount">
                <b-input-group>
                  <b-form-input id="payAmount" type="number"
                    v-model="staff.payAmount"
                    :readonly="isPayAmountReadOnly">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['payAmount'] != null">
              <b-col v-for="(field, index) in customFieldMap['payAmount']" :key="'payAmount'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
            
            <b-col v-if="isColorVisible" cols="12" md="4" class="pr-0">
              <div class="color-container">
                <Color :disabled="isColorReadOnly" v-model="staff.color" :update="updatedColor"/>
              </div>
            </b-col>

            <template v-if="customFieldMap['color'] != null">
              <b-col v-for="(field, index) in customFieldMap['color']" :key="'color'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" :md="isGeneric? (isResourceQuotaVisible? 8 : 12) : 12" class="pr-0" v-if="isPayCurrencyVisible">
              <b-form-group :label="$t(`staff.field.payCurrency`)" label-for="payCurrency" size="sm">
                <b-input-group>
                  <select id="payCurrency" class="custom-select" v-model="staff.payCurrency" :disabled="isPayCurrencyReadOnly">
                    <template v-for="(opt, index) in currencyOpts">
                      <option :value="opt.value" :key="index" :style="{ display: opt.num < 0? 'none': 'block' }">{{ opt.text }}</option>
                    </template>
                  </select>
                </b-input-group>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['payCurrency'] != null">
              <b-col v-for="(field, index) in customFieldMap['payCurrency']" :key="'payCurrency'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="4" class="pr-0" v-if="isGeneric && isResourceQuotaVisible">
              <b-form-group :label="$t('staff.field.resourceQuota')" label-for="resourceQuota">
                <b-input-group>
                  <b-input-group-prepend v-if="!isResourceQuotaReadOnly">
                    <b-button id="QUOTA_SUBTRACT" @click.prevent="quotaAddMinus(-1)">
                      <font-awesome-icon :icon="['far', 'minus']"/>
                      <b-popover
                        target="QUOTA_SUBTRACT"
                        placement="top"
                        triggers="hover"
                        :content="$t('staff.button.quota_subtract')">
                      </b-popover>
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input type="text" class="form-label"
                      v-model="staff.resourceQuota" @blur="onQuotaBlur"
                      :readonly="isResourceQuotaReadOnly"/>
                  <b-input-group-append v-if="!isResourceQuotaReadOnly">
                    <b-button id="QUOTA_ADD" @click.prevent="quotaAddMinus(1)">
                      <font-awesome-icon :icon="['far', 'plus']"/>
                      <b-popover
                        target="QUOTA_ADD"
                        placement="top"
                        triggers="hover"
                        :content="$t('staff.button.quota_add')">
                      </b-popover>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showStaffResourceQuotaError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('staff.resourceQuota') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['resourceQuota'] != null">
              <b-col v-for="(field, index) in customFieldMap['resourceQuota']" :key="'resourceQuota'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" class="pr-0" v-if="isPayFrequencyVisible">
              <b-form-group :label="$t(`staff.field.payFrequency`)" label-for="payFrequency">
                <b-input-group>
                  <b-form-select id="payFrequency" :options="payFrequencyOptions"
                    v-model="staff.payFrequency"
                    :disabled="isPayFrequencyReadOnly">
                  </b-form-select>
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showPayFrequencyError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('staff.payFrequency') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['payFrequency'] != null">
              <b-col v-for="(field, index) in customFieldMap['payFrequency']" :key="'payFrequency'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <template v-if="!isGeneric">
              <b-col cols="12" class="pr-0" v-if="isStorageFileVisible">
                <b-form-group>
                  <label class="mr-1">{{ $t(`staff.field.files`) }}</label>
                  <button v-if="!isStorageFileReadOnly" id="FILE_ADD" class="btn-action" @click="fileSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                    <b-popover
                      target="FILE_ADD"
                      placement="top"
                      triggers="hover"
                      :content="$t('staff.button.file_add')">
                    </b-popover>
                  </button>
                  <BadgeGroup v-model="files"  :readOnly="isStorageFileReadOnly">
                    <template v-slot:default="{ item, index }">
                      <Badge @badgeRemove="fileBadgeRemove(index)" @badgeClick="fileBadgeClick(item)"
                        :text="labelFilename(item.name, item.type)" 
                        variant="primary" 
                        :pillable="!!item.pillable" :key="index"
                        :readOnly="isStorageFileReadOnly"
                        enableClickWhenReadOnly
                         />
                      </template>
                  </BadgeGroup>
                </b-form-group>
              </b-col>
            </template>
            
            <template v-if="customFieldMap['files'] != null">
              <b-col v-for="(field, index) in customFieldMap['files']" :key="'files'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" class="pr-0" v-if="isTagVisible">
              <b-form-group>
                <TagList :holderId="id" :tags="tags" @modified="tagsModified" :readOnly="isTagReadOnly" />
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['tags'] != null">
              <b-col v-for="(field, index) in customFieldMap['tags']" :key="'tags'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
            
            <template v-if="isCalendarVisible">
            <b-col cols="12" class="pr-0">
              <b-form-group>
                <label class="mr-2">{{ $t('staff.field.calendar') }}</label>
                <button @click="showCalendar" class="btn-action btn-lg"><font-awesome-icon  :icon="['fad', 'calendar-days']"/></button>
              </b-form-group>
            </b-col>
            </template>

            <template v-if="customFieldMap['calendar'] != null">
              <b-col v-for="(field, index) in customFieldMap['calendar']" :key="'calendar'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" class="pr-0"  v-if="isNoteVisible">
              <b-form-group>
                <NoteList :readOnly="isNoteReadOnly" :notes="notes" @add="addNote" @edit="editNote" @toRemove="removeNote" />
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['notes'] != null">
              <b-col v-for="(field, index) in customFieldMap['notes']" :key="'notes'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="staff[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

          </b-row>
        </div>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <div class="user-buttons" v-if="!isAccessDenied && !isGeneric">
          <b-button size="sm" variant="secondary" @click="createUserAccount" class="mr-1" v-if="canAdd('USER') && id.indexOf('STAFF_NEW_') === -1 && !hasUserAccount">
            {{ $t('button.create_user_account') }}
          </b-button>
          <b-button size="sm" variant="secondary" @click="editUserAccount" class="mr-1" v-if="canEdit('USER') && id.indexOf('STAFF_NEW_') === -1 && hasUserAccount">
            {{ $t('button.edit_user_account') }}
          </b-button>
          <b-button size="sm" variant="secondary" @click="deleteUserAccount" class="mr-1" v-if="canDelete('USER') && id.indexOf('STAFF_NEW_') === -1 && hasUserAccount">
            {{ $t('button.delete_user_account') }}
          </b-button>
        </div>
        <template v-if="!isAccessDenied && (canEdit() || !exists)">
          <b-button size="sm" variant="success" disabled v-if="state.isSubmitting">
            <b-spinner small type="grow" />{{ $t('button.saving') }}
          </b-button>
          <b-button size="sm" variant="success" @click.prevent="preOk" :disabled="disableOk" v-else>{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <!-- skill selector -->
    <GenericSelectorModalForAdmin v-if="state.skillSelectorShow"
      :show.sync="state.skillSelectorShow" 
      :entityService="skillUtil"
      entity="SKILL"
      nonAdmin
      @ok="skillSelectorOk"
    />
    <SkillLevelModal 
      :show.sync="state.skillLevelEditShow" 
      :uuId="skillLevelEdit.uuId" 
      :name="skillLevelEdit.name" 
      :level="skillLevelEdit.level"
      :cData="skillLevelEdit.data"
      edgeName="STAFF-SKILL" 
      @ok="skillLevelOk"
    />
    <template v-for="(item, index) in toConfirmSkills">
      <SkillLevelModal 
        :key="`skill-${index}`" 
        :show.sync="item.show" 
        :uuId="item.uuId" 
        :name="item.name" 
        :level="item.level" 
        :cData="item"
        edgeName="STAFF-SKILL"
        @ok="toConfirmSkillOk" 
        @cancel="toConfirmSkillCancel" 
        canApplyAll/>
    </template>

    <!-- resource selector -->
    <GenericSelectorModalForAdmin v-if="state.resourceSelectorShow"
      :show.sync="state.resourceSelectorShow" 
      :entityService="resourceUtil"
      entity="RESOURCE"
      nonAdmin
      @ok="resourceSelectorOk"
    />

    <ResourceUnitModal :show.sync="state.resourceUnitEditShow" 
      :uuId="resourceUnitEdit.uuId" 
      :name="resourceUnitEdit.name" 
      :unit="resourceUnitEdit.unit" 
      :utilization="parseFloat(resourceUnitEdit.utilization)" 
      :cData="resourceUnitEdit.data"
      edgeName="STAFF-RESOURCE"
      @ok="resourceUnitOk"/>
    <template v-for="(item, index) in toConfirmResources">
      <ResourceUnitModal :key="`resource-${index}`" 
        :show.sync="item.show" 
        :uuId="item.uuId" 
        :name="item.name" 
        :unit="parseInt(item.unit)" 
        :utilization="parseFloat(item.utilization)" 
        :cData="item"
        edgeName="STAFF-RESOURCE"
        @ok="toConfirmResourceOk" 
        @cancel="toConfirmResourceCancel" 
        canApplyAll />
    </template>

    <!-- company selector -->
    <CompanySelectorModalForAdmin v-if="state.companySelectorShow"
      :show.sync="state.companySelectorShow" 
      :preselected="companyEditId"
      nonAdmin
      singleSelection
      @ok="companySelectorOk"
    />
    
    <!-- location selector -->
    <GenericSelectorModalForAdmin v-if="state.locSelectorShow"
      :show.sync="state.locSelectorShow" 
      :preselected="locationEditId"
      :entityService="locationUtil" 
      entity="LOCATION"
      nonAdmin
      singleSelection
      @ok="locSelectorOk"
    />

    <CalendarModal :readOnly="isReadOnly" :id="id" :calendar="calendar" calendarName="staff" :calendarOrder="calendarOrder" :show.sync="state.calShow" @ok="calOk"/>
    <UserModal v-if="userShow" :staffData="userData" :id="userId" :show.sync="userShow" :title="userTitle" @success="createUserAccountSuccess" />
    
    <!-- department selector -->
    <DepartmentSelectorModalForAdmin v-if="state.depSelectorShow"
      :show.sync="state.depSelectorShow"
      :preselected="depPreselected"
      :company="Array.isArray(company) && company.length > 0 ? company[0] : null" 
      disableCompanySelection
      :selectorTitle="$t('department.title_selector')"
      nonAdmin
      @ok="depSelectorOk"
    />
      
    <template v-if="!isGeneric">
      <FileSelectorModal :show.sync="state.fileSelectorShow" @ok="fileSelectorOk" />
      <DownloadProgressModal :show.sync="downloadProgressShow" :downloadPercentage="downloadPercentage" @cancel="downloadCancel"/>
    </template>

    <template v-if="exists">
      <GenericHistoryModal v-if="state.historyShow" :show.sync="state.historyShow" :id="isGeneric? id : contractStaffId" entityType="STAFF" :customFields="customFields" links="COMPANY,DEPARTMENT,LOCATION,NOTE,RESOURCE,SKILL,STORAGE_FILE,TAG,CALENDAR" />
      <NoteModal v-if="state.noteShow" :show.sync="state.noteShow" :note="note" @toAdd="toAddNote" @toUpdate="toUpdateNote"/>
    </template>
    
    <b-modal :title="$t('staff.confirmation.title_email_change')"
        v-model="state.confirmEmailChangeShow"
        :ok-title="$t('button.continue')"
        @ok="confirmEmailChangeOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        <p>{{ $t('staff.confirmation.email_change_line1',[this.originEmail]) }}</p>
        <p>{{ $t('staff.confirmation.email_change_line2') }}</p>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="confirmEmailChangeOk">{{ $t('button.continue') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <b-modal :title="$t('user.confirmation.title_delete')"
        v-model="confirmDeleteShow"
        :ok-title="$t('button.confirm')"
        @ok="confirmDeleteOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        <p>{{ $t('staff.delete_user') }}</p>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="confirmDeleteOk">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('staff.confirmation.title_confirm_save_before_proceed')"
        v-model="state.saveBeforeContractStaffShow"
        @close="confirmSaveBeforeContractCancel"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        <p>{{ $t('staff.confirmation.contract_changed') }}</p>
      </div>
      <template v-slot:modal-footer="{ }">
        <b-button size="sm" variant="success" @click="confirmSaveBeforeContractOk(true)">{{ $t('button.save') }}</b-button>
        <b-button size="sm" variant="danger" @click="confirmSaveBeforeContractOk(false)">{{ $t('button.no') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('staff.confirmation.title_confirm_remove_contract')"
        v-model="state.confirmRemoveContractShow"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        <p>{{ $t('staff.confirmation.contract_remove') }}</p>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="confirmRemoveContractOk()">{{ $t('button.delete') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
// Scripts
import { persistNotes } from '@/components/Note/script/crud-util';
import { updateTags } from '@/components/Tag/script/crud-util';
import { updateFiles } from '@/helpers/file';
import { updateLocation } from '@/helpers/location';
import { updateSkills } from '@/helpers/skill';
import { updateDepartments } from '@/helpers/department';
import { updateCompanies } from '@/helpers/company';
import { updateResources } from '@/helpers/resource';
import { getCustomFieldInfo, customFieldValidate, filterCustomFields } from '@/helpers/custom-fields';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import { cloneDeep } from 'lodash';
import { 
  processCalendar, strRandom, forceFileDownload, labelFilename, 
  loadViewProfile, updateViewProfile 
} from '@/helpers';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { phoneNrs, socialTypes } from "@/selectOptions";
import currencies from '@/views/management/script/currencies';
import { locationUtil } from '@/views/management/script/location';

// Service 
import { 
  staffService, staffLinkSkillService, staffLinkFileService,
  staffLinkLocationService, staffLinkResourceService,
  departmentLinkStaffService, userService, staffLinkCompanyService,
  staffLinkTagService
} from '@/services';
import { removeDeniedProperties } from '@/views/management/script/common';
import { getAppendAfterObjectWithTopDownRelationship } from '@/components/modal/script/field';
import { resourceUtil } from '@/views/management/script/resource';
import { skillUtil } from '@/views/management/script/skill';

export default {
  name: 'StaffModal',
  components: {
    AvatarBanner: () => import('@/components/AvatarBanner/AvatarBanner'),
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    SkillLevelModal: () => import('@/components/modal/SkillLevelModal'),
    ResourceUnitModal: () => import('@/components/modal/ResourceUnitModal'),
    CalendarModal: () => import('@/components/modal/CalendarModal'),
    FileSelectorModal: () => import('@/components/modal/FileSelectorModal'),
    DownloadProgressModal: () => import('@/components/modal/DownloadProgressModal'),
    UserModal: () => import('@/components/modal/UserModal'),
    GenericHistoryModal: () => import('@/components/modal/GenericHistoryModal'),
    NoteList: () => import('@/components/Note/NoteList.vue'),
    NoteModal: () => import('@/components/modal/NoteModal.vue'),
    TagList: () => import('@/components/Tag/TagList.vue'),
    Color: () => import('@/components/Color/Color.vue'),
    CustomField: () => import('@/components/CustomField.vue'),
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin'),
    CompanySelectorModalForAdmin: () => import('@/components/modal/CompanySelectorModalForAdmin'),
    DepartmentSelectorModalForAdmin: () => import('@/components/modal/DepartmentSelectorModalForAdmin'),
  },
  props: {
    id:        { type: String,   default: `STAFF_NEW_${strRandom(5)}` },
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true },
    isGeneric:  { type: Boolean, default: false }
  },
  data() {
    return {
      permissionName: 'STAFF',
      modelInfo: null,
      alertMsg: null,
      alertError: false,
      alertMsgDetails: [],
      settings: {},
      state: {
        editable:             false,
        isSubmitting:         false,
        modalShow:            false,
        skillSelectorShow:    false,
        skillLevelEditShow:   false,
        depSelectorShow:      false,
        locSelectorShow:      false,
        resourceSelectorShow: false,
        resourceUnitEditShow: false,
        companySelectorShow: false,
        calShow:              false,
        confirmEmailChangeShow: false,
        fileSelectorShow:     false,
        historyShow:          false,
        noteShow:             false,
        saveBeforeContractStaffShow: false,
        confirmRemoveContractShow: false
      },
      avatarBanner: {
        avatarId: null,
        bannerId: null
      },

      contracts:                 [],
      contractStaffId:           null,
      previousContractStaffId:   null,
      saveBeforeContractStaffId: null,
      triggeredByContractStaffChanged: false,
      saveTriggeredByContractStaffChanged: false,
      notToUpdatePrevContractStaffId: false,
      disableContractAddBtn:     false,
      disableContractRemoveBtn:  false,

      staff: {
        uuId:                  null,
        firstName:             null,
        lastName:              null,
        position:              null,
        email:                 null,
        payAmount:             0,
        payFrequency:          'Annually',
        payCurrency:           null,
        startDate:             null,
        endDate:               null,
        websites:            [null],
        socials:          [{ kind: null, data: null }],
        phones:              [{ kind: null, data: null }],
        identifier:            null,
        staffType:             null,
        color:                 null,
        resourceQuota:         null,
        readOnly:              false
      },

      departments: [
        // { uuId: "1", name: "IT Support" }
      ],
      depPreselected: null,
      
      company: [],
      companyEditId: null,

      skills: [
        // { uuId: "1", name: "Rigging", level: "Senior" }
      ],
      toConfirmSkills: [],
      skillLevelEdit: {
        uuId:  null,
        name:  null,
        level: null,
      },

      resources: [
        // { uuId: "1", name: "Desk", quantity: 1, utilization: 1.00 }
      ],
      toConfirmResources: [],
      resourceUnitEdit: {
        uuId: null,
        name: null,
        unit: null,
        utilization: null
      },
      location: [],
      locationEditId: null,
      files: [],
      notes: [],
      note: {
        uuId: null,
        text: null,
        identifier: null
      },
      
      downloadProgressShow: false,
      downloadPercentage: 0,
      downloadCancelTokenSource: null,
      downloadCancelled: false,
      
      calendar: {},
      calendarOrder: ["staff", "location", "base_calendar"],
      
      user: null,
      userId: null,
      userShow: false,
      userData: null,
      confirmDeleteShow: false,
      typeOptions: [],
      tags: [],
      updatedColor: null,

      isAccessDenied: false,
      
      customFields: [],
      customFieldMap: {},

      restrictedRequiredField: null
    }
  },
  created() {
    this.getModelInfo();
    this.fieldValidateUtil = fieldValidateUtil;
    this.original = {
      readOnly: false
    }
    this.currencyOpts = [];
    this.originSkills = [];
    this.originDeps = [];
    this.originResources = [];[{"firstName":"Test Generic Staff","payAmount":"10000","payFrequency":"Annually","startDate":"1970-01-01","endDate":"3000-01-01","staffType":"Casual"}]
    this.originLocId = null;
    this.originEmail = null;
    this.originUserEmail = null;
    this.originTags = [];
    this.DATE_START_AS_NULL = '1970-01-01';
    this.DATE_END_AS_NULL = '3000-01-01';
    this.originFiles = [];
    this.optionSocials = socialTypes;
    this.optionPhoneNrs = phoneNrs;
    this.dataFunc = staffService.history;
    this.originCompany = [];
    this.originStaff = null;
    this.originNotes = [];
    this.locationUtil = locationUtil;
    this.resourceUtil = resourceUtil;
    this.skillUtil = skillUtil;
  },
  beforeMount() {
    if (this.show) {
      this.processWhenShowModal(this.show)
    }
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.currencyOpts = null;
    this.originSkills = null;
    this.originDeps = null;
    this.originResources = null;
    this.originLocId = null;
    this.DATE_START_AS_NULL = null;
    this.DATE_END_AS_NULL = null;
    this.originEmail = null;
    this.originUserEmail = null;
    this.originFiles = null;
    this.optionSocials = null;
    this.optionPhoneNrs = null;
    this.dataFunc = null;
    this.originCompany = null;
    this.originStaff = null;
    this.originNotes = null;
    this.originTags = null;
    this.locationUtil = null;
    this.resourceUtil = null;
    this.skillUtil = null;
  },
  computed: {
    customFieldsFiltered() {
      return this.customFields.filter(f => this.canView(this.permissionName, [f.name]) && ((!this.exists && this.canAdd(this.permissionName, [f.name]))
      || this.exists));
    },
    componentId() {
      return `STAFF_FORM_${this.id}`;
    },
    isReadOnly() {
      return !this.state.editable || this.readOnly || this.staff.readOnly;
    },
    showError() {
      return this.alertMsg != null;
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'staff.firstName');
    },
    showFamilyNameError() {
      return fieldValidateUtil.hasError(this.errors, 'staff.lastName');
    },
    showPositionError() {
      return fieldValidateUtil.hasError(this.errors, 'staff.position');
    },
    showEmailError() {
      return fieldValidateUtil.hasError(this.errors, 'staff.email');
    },
    showCompanyError() {
      return fieldValidateUtil.hasError(this.errors, 'staff.company');
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
    showLocationError() {
      return fieldValidateUtil.hasError(this.errors, 'location');
    },
    showPayFrequencyError() {
      return fieldValidateUtil.hasError(this.errors, 'staff.payFrequency');
    },
    showStaffTypeError() {
      return fieldValidateUtil.hasError(this.errors, 'staff.staffType');
    },
    showStaffResourceQuotaError() {
      return fieldValidateUtil.hasError(this.errors, 'staff.resourceQuota');
    },
    labelTitle() {
      return this.title? this.title : (this.isGeneric? this.$t('staff.title_generic_new') : this.$t('staff.title_new'));
    },
    hasUserAccount() {
      return this.user !== null;
    },
    userTitle() {
      return this.$t('user.title_new');
    },
    exists() {
      return this.id && !this.id.startsWith('STAFF_NEW_');
    },
    companyTitle() {
      return this.isReadOnly? this.$t('staff.title_company_view') : this.$t('staff.title_company_edit');
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "identifier";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    minQuotaValue() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "resourceQuota";
      });
      return values.length !== 0 ? values[0].min : 1;
    },
    maxQuotaValue() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "resourceQuota";
      });
      return values.length !== 0 ? values[0].max : 100000;
    },
    payFrequencyOptions() {
      let options = null;
      if (this.modelInfo != null) {
        const rawOptions = this.modelInfo.find(f => f.field === 'payFrequency').options;
        if (rawOptions != null) {
          options = rawOptions.map(i => {
            return { value: i, text: this.$t(`payFrequency.${i}`) }
          });
        }
      }
      if (options != null) {
        return options;
      }
      return [];
    },
    isAvatarBannerVisible() {
      return !this.isGeneric && this.canView('STORAGE_FILE') && this.canView(this.permissionName, ['avatarRef', 'bannerRef'])
      && ((!this.exists && this.canAdd(this.permissionName, ['avatarRef', 'bannerRef'])) || this.exists)
    },
    isAvatarBannerReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['avatarRef', 'bannerRef']))
    },
    isFirstNameVisible() {
      //FirstName is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['firstName'])
    },
    isFirstNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['firstName']))
    },
    isLastNameVisible() {
      //For non-generic, lastName is mandatory field. Skip checking canAdd().
      return !this.isGeneric && this.canView(this.permissionName, ['lastName'])
    },
    isLastNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['lastName']))
    },
    isEmailVisible() {
      //Email is mandatory field so skip checking against canAdd()
      return !this.isGeneric && this.canView(this.permissionName, ['email'])
    },
    isEmailReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['email']))
    },
    isCompanyVisible() {
      //Company is Mandatory field for non-generic staff. Skip checking canAdd()
      return this.canView('COMPANY', ['STAFF'])
    },
    isCompanyReadOnly() {
      return this.isReadOnly || !this.isGeneric && !this.canEdit('COMPANY', ['STAFF'])
    },
    isDepartmentVisible() {
      return this.canView('DEPARTMENT', ['STAFF'])
    },
    isDepartmentReadOnly() {
      return this.isReadOnly || !this.canEdit('DEPARTMENT', ['STAFF'])
    },
    isPositionVisible() {
      return !this.isGeneric && this.canView(this.permissionName, ['position']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['position'])) || this.exists)
    },
    isPositionReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['position']))
    },
    isStaffTypeVisible() {
      //StaffType is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['staffType'])
    },
    isStaffTypeReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['staffType']))
    },
    isLocationVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('LOCATION') && this.canView(this.permissionName, ['LOCATION']) 
      && ((!this.exists && this.canEdit(this.permissionName, ['LOCATION'])) || this.exists)
    },
    isLocationReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['LOCATION'])
    },
    isPhonesVisible() {
      return !this.generic && this.canView(this.permissionName, ['phones']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['phones'])) || this.exists)
    },
    isPhonesReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['phones']))
    },
    isWebsitesVisible() {
      return !this.generic && this.canView(this.permissionName, ['websites']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['websites'])) || this.exists)
    },
    isWebsitesReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['websites']))
    },
    isSocialsVisible() {
      return !this.generic && this.canView(this.permissionName, ['socials'])
      && ((!this.exists && this.canAdd(this.permissionName, ['socials'])) || this.exists)
    },
    isSocialsReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['socials']))
    },
    isStartDateVisible() {
      //StartDate is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['startDate'])
    },
    isStartDateReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['startDate']))
    },
    isEndDateVisible() {
      //EndDate is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['endDate'])
    },
    isEndDateReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['endDate']))
    },
    isSkillVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('SKILL') && this.canView(this.permissionName, ['SKILL']) 
      && ((!this.exists && this.canEdit(this.permissionName, ['SKILL'])) || this.exists)
    },
    isSkillReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['SKILL'])
    },
    isResourceVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('RESOURCE') && this.canView(this.permissionName, ['RESOURCE']) 
      && ((!this.exists && this.canEdit(this.permissionName, ['RESOURCE'])) || this.exists)
    },
    isResourceReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['RESOURCE'])
    },
    isPayAmountVisible() {
      return this.canView(this.permissionName, ['payAmount']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['payAmount'])) || this.exists)
    },
    isPayAmountReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['payAmount']))
    },
    isPayCurrencyVisible() {
      return this.canView(this.permissionName, ['payCurrency']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['payCurrency'])) || this.exists)
    },
    isPayCurrencyReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['payCurrency']))
    },
    isPayFrequencyVisible() {
      return this.canView(this.permissionName, ['payFrequency']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['payFrequency'])) || this.exists)
    },
    isPayFrequencyReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['payFrequency']))
    },
    isStorageFileVisible() {
      //Link creation to check against canEdit()
      return !this.isGeneric && this.canView(this.permissionName, ['STORAGE_FILE']) 
      && ((!this.exists && this.canEdit(this.permissionName, ['STORAGE_FILE'])) || this.exists)
    },
    isStorageFileReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['STORAGE_FILE']))
    },
    isCalendarVisible() {
      return this.canView('CALENDAR')
    },
    isIdentifierVisible() {
      return this.canView(this.permissionName, ['identifier'])
       && ((!this.exists && this.canAdd(this.permissionName, ['identifier'])) || this.exists)
    },
    isIdentifierReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['identifier']))
    },
    isColorVisible() {
      return this.canView(this.permissionName, ['color']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['color'])) || this.exists)
    },
    isColorReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['color']))
    },
    isTagVisible() {
      //Tag field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('TAG') && this.canView(this.permissionName, ['TAG'])
    },
    isTagReadOnly() {
      return this.isReadOnly || !this.canAdd('TAG') || !this.canEdit('TAG') || !this.canEdit(this.permissionName, ['TAG'])
    },
    isNoteVisible() {
      //Note field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('NOTE') && this.canView(this.permissionName, ['NOTE'])
    },
    isNoteReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['NOTE'])
    },
    isResourceQuotaVisible() {
      return this.canView(this.permissionName, ['resourceQuota']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['resourceQuota'])) || this.exists)
    },
    isResourceQuotaReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['resourceQuota']))
    },
    disableOk() {
      return (this.original.readOnly && this.staff.readOnly) || this.state.isSubmitting;
    },
    isLockVisible() {
      return this.canView(this.permissionName, ['readOnly'])
      && ((!this.exists && this.canAdd(this.permissionName, ['readOnly'])) || this.exists)
    },
    isLockReadOnly() {
      return !this.state.editable || this.readOnly || (this.exists && !this.canEdit(this.permissionName, ['readOnly']))
    }
  },
  watch: {
    async show(newValue) {
      if(newValue == true && newValue != this.state.modalShow) {
        this.processWhenShowModal(newValue)
      }
      else if(newValue == false && newValue != this.state.modalShow) {
        this.state.modalShow = newValue;
      }
    },
    contractStaffId(newValue, oldValue) {
      if (this.notToUpdatePrevContractStaffId) {
        this.notToUpdatePrevContractStaffId = false;
      } else {
        this.previousContractStaffId = oldValue;
      }

      if (this.triggeredByContractStaffChanged) {
        this.triggeredByContractStaffChanged = false;
        //Detect if there is unsaved change. If yes, prompt to ask user whether to save it.
        //Prompt to ask without check for new contract staff
        if ((this.previousContractStaffId != null && this.previousContractStaffId.includes('CONTRACT_STAFF_NEW_')) || this.hasNonGenericDataChanged()) {
          this.saveBeforeContractStaffId = this.previousContractStaffId;
          this.state.saveBeforeContractStaffShow = true;
        } else {
          //Load new staff data
          this.staffGet(this.contractStaffId);
        }
      }
    }
  },
  methods: {
    async processWhenShowModal(newValue) {
      await getCustomFieldInfo(this, 'STAFF');
      if (this.customFields.length == 0) {
        this.customFieldMap = {};
      } else {
        this.customFieldMap = getAppendAfterObjectWithTopDownRelationship(this.customFields, this.allowViewFunc);
      }
      this.resetProperties();

      await Promise.allSettled([
        this.$store.dispatch('data/enumList'),
        loadViewProfile(this.settings, this.$store.state.authentication.user.uuId)
      ])
      .then(responses => {
        const enumResponse = responses[0]
        const loadViewProfileResponse = responses[1]

        if (enumResponse.status == 'fulfilled') {
          const response = enumResponse.value
          if (response.jobCase != null && response[response.jobCase] != null) {
            const propertyList = response[response.jobCase]
            if (propertyList != null) {
              if (propertyList.StaffTypeEnum != null) {
                const obj = propertyList.StaffTypeEnum
                const codes = Object.keys(obj)
                const list = []
                for (const c of codes) {
                  list.push({ value: c, text: c, num: obj[c] })
                }
                this.typeOptions.splice(0, this.typeOptions.length, ...list)
              }
              if (propertyList.CurrencyEnum != null) {
                const obj = propertyList.CurrencyEnum
                const codes = Object.keys(obj)
                const list = []
                for (const c of codes) {
                  const found = currencies.find(i => i.code == c)
                  const text = found != null && found.name != null? `${c} (${found.name})` : c
                  list.push({ value: c, text, num: obj[c] })
                }
                this.currencyOpts.splice(0, this.currencyOpts.length, ...list)
              }
            } 
          }
        }

        if (loadViewProfileResponse.status == 'fulfilled') {
          this.settings = loadViewProfileResponse.value
        }
      })
      
      let currency = null
      if (this.settings.currency != null && this.currencyOpts.find(i => i.value == this.settings.currency && i.num > -1) != null) {
        currency = this.settings.currency;
      }

      if (currency == null && this.currencyOpts.find(i => i.num > -1) != null) {
        currency = this.currencyOpts.find(i => i.num > -1).value
      }

      this.staff.payCurrency = currency;
      
      this.state.modalShow = newValue;
      this.state.noteShow = false;
      this.originNotes = [];
      this.notes = [];
      this.alertMsg = null;
      this.alertError = false;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      this.state.editable =  (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
      this.restrictedRequiredField = null;
      if(this.id.indexOf('STAFF_NEW_') === -1) {
        this.staffGet(this.id);
      } else {
        if (newValue) {
          const requiredFields = this.isGeneric? ['firstName', 'type','LOCATION', 'payAmount', 'payFrequency', 'genericStaff'] : 
                                  ['lastName', 'firstName', 'type', 'email','LOCATION', 'payAmount', 'payFrequency', 'genericStaff']
          const requiredCustomFields = this.customFields.filter(i => i.notNull == true).map(i => i.name);
          if (requiredCustomFields.length > 0) {
            requiredFields.push(...requiredCustomFields);
          }
          
          let result = this.canView2(this.permissionName, requiredFields);
          if (result.val) {
            result = this.canAdd2(this.permissionName, requiredFields);
          }

          if (result.val && !(this.canAdd(this.permissionName, requiredFields) && this.canEdit('COMPANY', ['STAFF']))) {
            result = { val: false, restrictedProp: 'COMPANY' }
          }

          if (result.val && !(this.canView('LOCATION') && this.canEdit(this.permissionName, ['LOCATION']))) {
            result = { val: false, restrictedProp: 'LOCATION' }
          }

          if (result.restrictedProp != null) {
            this.restrictedRequiredField = this.getDisplayNameOfProperty(result.restrictedProp);
          }

          if (result.val) {
            this.isAccessDenied = false;
          } else {
            this.isAccessDenied = true;
          }
        } else {
          this.isAccessDenied = false;
        }
      }
    },
    getDisplayNameOfProperty(val) {
      if (val == 'LOCATION') {
        return this.$t('staff.field.location');
      } else if (val == 'COMPANY') {
        return  this.$t('staff.field.company');
      } else {
        const found = this.customFields.find(i => i.name == val);
        if (found != null) {
          return found.displayName;
        }
        return  this.$t(`staff.field.${val}`);
      }
    },
    getModelInfo() {
      this.$store.dispatch('data/info', {type: "api", object: "STAFF"}).then(value => {
        this.modelInfo = value.STAFF.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    createUserAccount() {
      const data = {
        firstName: this.staff.firstName
        , lastName: this.staff.lastName
        , email: this.staff.email
        , color: this.staff.color
      }
      if(this.avatarBanner) {
        if(this.avatarBanner.avatarId != null) {
          data.avatarRef = this.avatarBanner.avatarId;
        }
        if(this.avatarBanner.bannerId != null) {
          data.bannerRef = this.avatarBanner.bannerId;
        }
      }
      this.userData = data;
      this.userShow = true;
    },
    createUserAccountSuccess() {
      this.alertError = false;
      this.alertMsg = this.$t(`staff.created_user`);
      this.staffGet(this.contractStaffId != null? this.contractStaffId : this.id);
    },
    editUserAccount() {
      this.userId = this.user.uuId;
      this.userShow = true;
    },
    deleteUserAccount() {
      this.confirmDeleteShow = true;
    },
    confirmDeleteOk() {
      userService.remove([{uuId: this.user.uuId }]).then(() => {
        this.alertError = false;
        this.alertMsg = this.$t(`staff.user_deleted`);
        // update data
        this.user = null;
        this.staffGet(this.contractStaffId != null? this.contractStaffId : this.id);
      }).catch(() => {
        this.alertError = true;
        this.alertMsg = this.$t(`user.error.failed_to_delete_user`);
      });
      this.confirmDeleteShow = false;
    },
    fieldItemAdd(key, index, _default) {
      this.staff[key].splice(index+1, 0, _default);
    },
    fieldItemRemove(key, index, _default) {
      this.staff[key].splice(index, 1);
      if (this.staff[key].length == 0) {
        this.staff[key].push(_default);
      }
    },
    labelFilename(name, type) {
      return labelFilename(name, type);
    },
    modalCancel() {
      this.$emit('update:show', false);
    },
    companySelectorToggle() {
      this.companyEditId = null;
      this.state.companySelectorShow = true;
    },
    companySelectorOk({ details }) {
      const currentIds = this.company.map(i => i.uuId);
      const newCompany = details.filter(i => !currentIds.includes(i.uuId));

      for(const c of newCompany) {
        this.company = [ {uuId: c.uuId, name: c.name, type: c.type} ];
        if(this.id.indexOf('STAFF_NEW_') !== -1) {
          // only set the company avatar and banner if new staff
          this.avatarBanner.avatarId = c.avatarRef;
          this.avatarBanner.bannerId = c.bannerRef;
        }
        this.departments = []; // clear the departments
      }
    },
    companyBadgeRemove: function(index) {
      this.company.splice(index,1);
      this.departments = [];
    },
    companyBadgeClick(id) {
      this.companyEditId = id;
      this.state.companySelectorShow = true;
    },
    ok() {
      this.errors.clear();
      this.alertMsg = null;
      this.alertError = false;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
    
      const customFields = this.customFieldsFiltered;
      for (const field of customFields) {
        if (!customFieldValidate(field, this.staff[field.name])) {
          field.showError = true;
          return;  
        }
      }
      
      if(!this.staff.firstName || this.staff.firstName.trim().length < 1) {
        this.errors.add({
          field: `staff.firstName`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t(this.isGeneric? 'staff.field.name': 'staff.field.firstName')])
        });
      }
      if(this.location.length === 0) {
        this.errors.add({
          field: `location`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('staff.field.location')])
        });
      }

      if(this.company.length === 0) {
        this.errors.add({
          field: `staff.company`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('staff.field.company')])
        });
      }

      if(!this.isGeneric) {
        if(!this.staff.email || this.staff.email.trim().length < 1) {
          this.errors.add({
            field: `staff.email`,
            msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('staff.field.email')])
          });
        }
        
        if(!this.staff.lastName || this.staff.lastName.trim().length < 1) {
          this.errors.add({
            field: `staff.lastName`,
            msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('staff.field.lastName')])
          });
        }
      }

      const isPayAmountValid = this.staff.payAmount > 0 && ((!this.exists && this.canAdd(this.permissionName, ['payAmount'])) 
            || (this.exists && this.canEdit(this.permissionName, ['payAmount'])))
      if (isPayAmountValid && this.staff.payFrequency == null 
          && ((this.exists && this.canEdit(this.permissionName, ['payFrequency'])) || (!this.exists && this.canAdd(this.permissionName, ['payFrequency'])))) {
        this.errors.add({
          field: `staff.payFrequency`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('staff.field.payFrequency')])
        });
      }

      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.staffSubmit();
        } else {
          this.alertError = true;
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
    },
    async staffSubmit() {
      if (this.settings.currency !== this.staff.payCurrency) {
        this.settings.currency = this.staff.payCurrency;
        await updateViewProfile(this.settings, this.$store.state.authentication.user.uuId);
      }
      
      const data = cloneDeep(this.staff);
      if(data.websites != null) {
        data.websites = data.websites.filter(i => i != null && i.length > 0);
      }
      if(data.socials != null) {
        data.socials = data.socials.filter(i => i != null && i.kind != null && i.data != null && i.data.length > 0);
      }
      if(data.phones != null) {
        data.phones = data.phones.filter(i => i != null && i.kind != null && i.data != null && i.data.length > 0);
      }
      data['avatarRef'] = this.avatarBanner.avatarId;
      data['bannerRef'] = this.avatarBanner.bannerId;
      if (data['avatarRef'] == null) {
        data['avatarRef'] = '00000000-0000-0000-0000-000000000000'
      }
      if (data['bannerRef'] == null) {
        data['bannerRef'] = '00000000-0000-0000-0000-000000000000'
      }

      if(this.isGeneric) {
        data.startDate = this.DATE_START_AS_NULL;
        data.endDate = this.DATE_END_AS_NULL;
        if (this.isResourceQuotaVisible) {
          let quota = parseInt(data.resourceQuota);
          if (isNaN(quota) || quota < 1) {
            data.resourceQuota = 1;
          }
        }
        
        delete data.email;
        delete data.lastName;
        delete data.position;
        delete data.websites;
        delete data.socials;
        delete data.phones;
        delete data.avatarRef;
        delete data.bannerRef;
      } else {
        delete data.resourceQuota;
        if(data['startDate']) {
          if (typeof data['startDate'] !== 'string') {
            data['startDate'] = moment(`${data['startDate'].getFullYear()}-${data['startDate'].getMonth() + 1}-${data['startDate'].getDate()}T${data['startDate'].getHours()}:${data['startDate'].getMinutes()}:${data['startDate'].getSeconds()}`, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
          }
        } else {
          data['startDate'] = this.DATE_START_AS_NULL;
        }

        if(data['endDate']) {
          if (typeof data['endDate'] !== 'string') {
            data['endDate'] = moment(`${data['endDate'].getFullYear()}-${data['endDate'].getMonth() + 1}-${data['endDate'].getDate()}T${data['endDate'].getHours()}:${data['endDate'].getMinutes()}:${data['endDate'].getSeconds()}`, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
          }
        } else {
          data['endDate'] = this.DATE_END_AS_NULL;
        }

        
        //contract is only for non-generic staff
        //replace data.uuId with the saveBfroreContractStaffId if it is triggered by contract staff changed
        if (this.saveTriggeredByContractStaffChanged) {
          data.uuId = this.saveBeforeContractStaffId;
        } 
        //replace data.uuId with the selected contract staffId if it is not null
        else if (this.contractStaffId != null && this.contractStaffId != this.id) {
          data.uuId = this.contractStaffId
        }
      }

      let method;
      if(this.id.indexOf('STAFF_NEW_') !== -1) {
        data['genericStaff'] = this.isGeneric;
        delete data['uuId'];
        method = 'create';
      } else if (data.uuId.indexOf('CONTRACT_STAFF_NEW_') !== -1) {
        method = 'create_as_contract';
      } else {
        method = 'update';
      }
      this.staffPost(method, data);
    },
    async staffPost(method, data) {
      this.state.isSubmitting = true;
      let staffId = null;
      let result = null;

      //Skip updating staff if there is no change in staff properties.
      let hasChanged = false;
      if (method != 'create' && method != 'create_as_contract') {
        hasChanged = this.removeUnchangedStaffProperties(data);
      }

      //Filter out properties which in denyRules
      const propKeys = Object.keys(data);
      const permissionName = method == 'create' || method == 'create_as_contract'? 'STAFF__ADD' : 'STAFF__EDIT';
      const permList = this.$store.state.authentication.user.permissionList.filter(f => f.name === permissionName);
      const perms = permList.length > 0 ? permList[0] : [];
      const denyRules = perms && perms.permissionLink && perms.permissionLink.denyRules ?
                        perms.permissionLink.denyRules : [];
      for (const k of propKeys) {
        if (denyRules.includes(k)) {
          delete data[k];
        }
      }

      if (method == 'create_as_contract') {
        result = await this.createContractStaff(data);
        if (result.hasError) {
          if (this.saveTriggeredByContractStaffChanged) {
            this.saveTriggeredByContractStaffChanged = false;
            //Revert contractStaffId value
            this.notToUpdatePrevContractStaffId = true;
            this.contractStaffId = this.previousContractStaffId;
          }
          this.alertMsg = result.msg;
          this.alertError = true;
          this.state.isSubmitting = false;
          return;
        }
        staffId = result.staffId;
      } else if (method == 'create' || hasChanged) {
        result = await this.updateStaff(method, data);
        if(result.hasError) {
          if (this.saveTriggeredByContractStaffChanged) {
            this.saveTriggeredByContractStaffChanged = false;
            //Revert contractStaffId value
            this.notToUpdatePrevContractStaffId = true;
            this.contractStaffId = this.previousContractStaffId;
          }
          this.alertMsg = result.msg;
          this.alertError = true;
          this.state.isSubmitting = false;
          return;
        }
        staffId = result.staffId;
      } else {
        staffId = data.uuId;
      }
      let hasError = false;
      
      // save the color in the profile
      this.updatedColor = data.color;
      const origLoc = [];
      if (this.originLocId !== null) {
        origLoc.push({ uuId: this.originLocId });
      }
      
      const locationResult = await updateLocation(staffId, staffLinkLocationService, method == 'create_as_contract'? [] : origLoc, this.location);
      if (locationResult.hasError) {
        hasError = true;
        this.alertMsg = this.$t(`staff.${method}_partial`);
        if (locationResult.errorCodes.length > 0) {
          if (locationResult.errorCodes.includes(403)) {
            this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('location.title').toLowerCase()]))
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('location.title').toLowerCase()]))
          }
        } else {
          this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('location.title').toLowerCase()]))
        }
      }

      // If we are creating a staff the backend will link the primary company automatically
      if ((method !== 'create' && method !== 'create_as_contract') || 
          (method === 'create' && this.company.type !== 'Primary')) {
        if (method == 'create') {
          this.originCompany = [this.$store.state.company];
        }
        const companyResult = await updateCompanies(staffId, staffLinkCompanyService, method == 'create_as_contract'? [] : this.originCompany, this.company)
        if (companyResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`staff.${method}_partial`);
          if (companyResult.errorCodes.length > 0) {
            if (companyResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('company.title').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('company.title').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('company.title').toLowerCase()]))
          }
        }
      }
      
      const tagResult = await updateTags(staffId, staffLinkTagService, method == 'create_as_contract'? [] : this.originTags, this.tags);
      if (tagResult.hasError) {
        hasError = true;
        this.alertMsg = this.$t(`staff.${method}_partial`);
        if (tagResult.errors.filter(i => i.response != null && i.response.status == 403).length > 0) {
          this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('tag.title').toLowerCase()]))
        } else {
          this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('tag.title').toLowerCase()]))
        }
      }
      
      const skillResult = await updateSkills(staffId, staffLinkSkillService, method == 'create_as_contract'? [] : this.originSkills, this.skills)
      if (skillResult.hasError) {
        hasError = true;
        this.alertMsg = this.$t(`staff.${method}_partial`);
        if (skillResult.errorCodes.length > 0) {
          if (skillResult.errorCodes.includes(403)) {
            this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('skill.title_selector').toLowerCase()]))
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('skill.title_selector').toLowerCase()]))
          }
        } else {
          this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('skill.title_selector').toLowerCase()]))
        }
      }
      
      if(!this.isGeneric) {
        const fileResult = await updateFiles(staffId, staffLinkFileService, method == 'create_as_contract'? [] : this.originFiles, this.files)
        if (fileResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`staff.${method}_partial`);
          if (fileResult.errorCodes.length > 0) {
            if (fileResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('file.title.list').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('file.title.list').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('file.title.list').toLowerCase()]))
          }
        }
      }
      
      const departmentResult = await updateDepartments(staffId, departmentLinkStaffService, method == 'create_as_contract'? [] : this.originDeps, this.departments)
      if (departmentResult.hasError) {
        hasError = true;
        this.alertMsg = this.$t(`staff.${method}_partial`);
        if (departmentResult.errorCodes.length > 0) {
          if (departmentResult.errorCodes.includes(403)) {
            this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('department.title_selector').toLowerCase()]))
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('department.title_selector').toLowerCase()]))
          }
        } else {
          this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('department.title_selector').toLowerCase()]))
        }
      }

      const resourceResult = await updateResources(staffId, staffLinkResourceService, method == 'create_as_contract'? [] : this.originResources, this.resources)
      if (resourceResult.hasError) {
        hasError = true;
        this.alertMsg = this.$t(`staff.${method}_partial`);
        if (resourceResult.errorCodes.length > 0) {
          if (resourceResult.errorCodes.includes(403)) {
            this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('resource.title_selector').toLowerCase()]))
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('resource.title_selector').toLowerCase()]))
          }
        } else {
          this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('resource.title_selector').toLowerCase()]))
        }
      }

      //Notes
      //Remove uuId of new notes before saving
      const notes = cloneDeep(this.notes);
      for (let i = 0, len = notes.length; i < len; i++) {
        if (notes[i].uuId != null && notes[i].uuId.startsWith('NEW_NOTE')) {
          delete notes[i].uuId;
        }
      }      
      const noteResult = await persistNotes(staffId, method == 'create_as_contract'? [] : this.originNotes, notes);
      if (noteResult.errors.length > 0 || noteResult.errorCodes.length > 0) {
        hasError = true;
        this.alertMsg = this.$t(`staff.${method}_partial`);
        if (noteResult.errorCodes.length > 0) {
          if (noteResult.errorCodes.includes(403)) {
            this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('notes').toLowerCase()]))
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
          }
        } else {
          this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
        }
      }

      this.state.isSubmitting = false;
      if (!hasError) {
        if (this.saveTriggeredByContractStaffChanged) {
          this.saveTriggeredByContractStaffChanged = false;
          //1) Update the record in contracts: populate the required properties for contract option label
          if (method == 'create_as_contract') {
            const found = this.contracts.find(i => i.uuId == staffId);
            found.uuId = staffId;
            found.startDate = data.startDate;
            found.endDate = data.endDate;
            found.name = `${data.firstName != null? data.firstName+' ' : ''}${data.lastName ?? ''}`;
            found.position = data.position;
          }
          this.$emit('refresh-list')
          //Proceed to load the contract staff
          this.staffGet(this.contractStaffId);
          return;
        }
        this.$emit('update:show', false);
        this.$emit('success', { msg: this.$t(`staff.${method}`) });
      } else {
        if (this.saveTriggeredByContractStaffChanged || method === 'create_as_contract') {
          this.saveTriggeredByContractStaffChanged = false;
          //Revert contractStaffId value
          this.notToUpdatePrevContractStaffId = true;
          this.contractStaffId = this.previousContractStaffId;
        }
        this.alertError = true;
        this.scrollToTop();
      }
    },
    async updateStaff(method, data) {
      const result = {
        hasError: false,
        msg: this.$t(`staff.${method}`)
      }
      removeDeniedProperties(this.permissionName, data, this.exists? 'EDIT':'ADD');
      let staffId = await staffService[method]([data])
      .then(response => {
        const data = response.data;
        return data[data.jobCase][0].uuId;
      }).catch(e => {
        result.hasError = true;
        result.msg = this.$t(`staff.error.failed_to_${method}_staff`);
        if (e.response && 403 === e.response.status) {
          result.msg = this.$t('error.authorize_action');
        } else if(e.response && 422 == e.response.status) {
          const list = e.response.data[e.response.data.jobCase];
          let hasFieldError = false;
          let hasEdgeAlreadyExisted = false;
          const clues = ['Missing_argument', 'Invalid_value', 'Not_unique_key']
          for(let i = 0, len = list.length; i < len; i++) {
            if('Already_have_edge' === list[i].clue) {
              hasEdgeAlreadyExisted = true;
              break;
            }
            if(clues.includes(list[i].clue)) {
              let spot = list[i].args || [];
              spot = spot.length > 0? spot[0] : '';
              spot = spot.length == 1? spot.toLowerCase() : spot.length > 1? spot.charAt(0).toLowerCase() + spot.substring(1) : spot;
              this.errors.add({
                field: `staff.${spot}`,
                msg: this.$t(`staff.error.${this.isGeneric && list[i].clue === 'Not_unique_key' ? 'generic_not_unique_key': list[i].clue.toLowerCase()}`, [this.$t(`staff.field.${spot}`)])
              });
              hasFieldError = true;
            }
          }
          if(hasEdgeAlreadyExisted) {
            result.hasError = false;
            return data.uuId;
          } else if(hasFieldError) {
            result.msg = this.$t(`error.attention_required`);
          }
        }
      });
      result.staffId = staffId;
      return result;
    },

    digestStaffResponse(data) {
      const s = this.staff;

      this.original.readOnly = data.readOnly;
      
      //Setup contracts
      if (Array.isArray(data.contractList) && data.contractList.length > 0) {
        if (this.contractStaffId == null) {
          this.contractStaffId = this.id;
        }
        data.contractList.sort(this.contractSort);
        for (const c of data.contractList) {
          if (Array.isArray(c.companyList) && c.companyList.length > 0) {
            c.company = c.companyList[0].name;
          }
        }
        this.contracts.splice(0, this.contracts.length, ...data.contractList);
      } else {
        
        this.contracts.splice(0, this.contracts.length, { 
          uuId: data.uuId
          , startDate: data.startDate
          , endDate: data.endDate
          , position: data.position
          , name: data.name
          , company: Array.isArray(data.companyList) && data.companyList.length > 0? data.companyList[0].name : null
        });
        this.contractStaffId = this.id;
      }

      //Setup staff data
      for (const key of Object.keys(s)) {
        if('startDate' === key) {
          s[key] = data[key] != null? ( this.DATE_START_AS_NULL !== data[key]? data[key] : null ) : null;
          continue;
        }
        if('endDate' === key) {
          s[key] = data[key] != null? ( this.DATE_END_AS_NULL !== data[key]? data[key] : null ) : null;
          continue;
        }
        if ('websites' === key) {
          s[key] = data[key] != null ? cloneDeep(data[key]) : [null];
          if(s[key].length == 0) {
            s[key].push(null);
          }
          continue;
        }
        if ('socials' === key || 'phones' === key) {
          s[key] = data[key] != null ? cloneDeep(data[key]) : [{ kind: null, data: null }];
          if(s[key].length == 0) {
            s[key].push({ kind: null, data: null });
          }
          continue;
        }
        
        s[key] = typeof data[key] !== 'undefined' ? data[key] : null;
      }
      
      for (const field of this.customFields) {
        if (typeof data[field.name] !== 'undefined') {
          s[field.name] = data[field.name];
        }
      }
      
      //Keep a copy of originEmail, used to determine if email has been modified.
      this.originEmail = s.email;
      if(data.user && data.user.email) {
        this.user = data.user;
        this.originUserEmail = data.user.email;
      }

      if (data.departmentList) {
        this.digestDepartmentResponse(data.departmentList);
      }
      
      s.uuId = data.uuId;
      //Setup avatarBanner data
      const ab = this.avatarBanner;
      if (data.avatarRef || data.bannerRef) {
        ab.avatarId = data.avatarRef;
        ab.bannerId = data.bannerRef;
        if (ab.avatarId == '00000000-0000-0000-0000-000000000000') {
          ab.avatarId = null;
        }
        if (ab.bannerId == '00000000-0000-0000-0000-000000000000') {
          ab.bannerId = null;
        }
      }

      //Setup Company data
      if (data.companyList && data.companyList.length > 0) {
        const list = data.companyList.map(i => { return { uuId: i.uuId, name: i.name, type: i.type }})
        this.originCompany.splice(0, this.originCompany.length, ...list);
        this.company.splice(0, this.company.length, ...cloneDeep(list));
      }
      else {
        this.company = [];
        this.originCompany = [];
      }

      //Setup Tags data
      if (data.tagList && data.tagList.length > 0) {
        const list = typeof data.tagList !== 'undefined' ? data.tagList : [];
        this.originTags.splice(0, this.originTags.length, ...list);
        this.tags.splice(0, this.tags.length, ...cloneDeep(list));
      } else {
        this.originTags = [];
        this.tags = [];
      }
      
      //Setup Skills data
      if (data.skillList && data.skillList.length > 0) {
        const list = data.skillList.map(i => { return { uuId: i.uuId, name: i.name, level: i.skillLink.level, ...filterCustomFields(i.skillLink, ['uuId']) }})
        this.originSkills.splice(0, this.originSkills.length, ...list);
        this.skills.splice(0, this.skills.length, ...cloneDeep(list));
      } else {
        this.originSkills = [];
        this.skills = [];
      }

      //Setup Location data
      if (data.locationList && data.locationList.length > 0) {
        const list = data.locationList.map(i => { return { uuId: i.uuId, name: i.name }})
        const location = list.length > 0? list[0] : null;
        this.originLocId = location? location.uuId : null;
        if(location) {
          this.location = [{ uuId: location.uuId, name: location.name }];
        }
      }
      else {
        this.originLocId = null;
        this.location = [];
      }

      //Setup Files data
      if (data.storageFileList && data.storageFileList.length > 0) {
        const list = data.storageFileList.map(i => { return { uuId: i.uuId, name: i.name, type: i.type }})
        this.originFiles.splice(0, this.originFiles.length, ...list);
        this.files.splice(0, this.files.length, ...cloneDeep(list));
      } else {
        this.originFiles = [];
        this.files = [];
      }

      //Setup Resources data
      if (data.resourceList && data.resourceList.length > 0) {
        const list = data.resourceList.map(i => { return { uuId: i.uuId, name: i.name, quantity: i.resourceLink? i.resourceLink.quantity :null, utilization: i.resourceLink? i.resourceLink.utilization: null, ...filterCustomFields(i.resourceLink, ['uuId']) }})
        this.originResources.splice(0, this.originResources.length, ...list);
        this.resources.splice(0, this.resources.length, ...cloneDeep(list));
      } else {
        this.originResources = [];
        this.resources = [];
      }

      //Setup Comment data
      this.notes = typeof data.noteList !== 'undefined' ? data.noteList : [];
      this.notes.sort((a, b) => {
        return b.modified - a.modified;
      });
      this.originNotes = cloneDeep(this.notes);
      if (data.noteList && data.noteList.length > 0) {
        const container = this.$refs['comments'];
        if (typeof container !== 'undefined') {
          container.scrollTop = container.scrollHeight;
        }
      }

      this.originStaff = cloneDeep(s);
      this.originStaff.avatarRef = data.avatarRef ? data.avatarRef : "00000000-0000-0000-0000-000000000000";
      this.originStaff.bannerRef = data.bannerRef ? data.bannerRef : "00000000-0000-0000-0000-000000000000";
      this.originStaff.durationAUM = data.durationAUM;
      this.originStaff.startDate = data.startDate;
      this.originStaff.endDate = data.endDate;
      this.originStaff.websites = data.websites != null? cloneDeep(data.websites) : [];
      this.originStaff.socials = data.socials != null? cloneDeep(data.socials) : [];
      this.originStaff.phones = data.phones != null? cloneDeep(data.phones) : [];
    },
    digestDepartmentResponse(data) {
      this.originDeps = cloneDeep(data);
      this.departments.splice(0, this.departments.length);
      const depList = this.departments;
      for(let i = 0, len = data.length; i < len; i++) {
        depList.push({ uuId: data[i].uuId, name: data[i].name });
      }
    },
    async staffGet(staffId) {
      //Reset alert
      this.errors.clear();
      this.alertMsg = null;
      this.alertError = false;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      //Triggered when user click add new contract staff button.
      if (staffId.includes('CONTRACT_STAFF_NEW_')) {
        //reset NoteList
        this.notes.splice(0, this.notes.length);
        this.disableContractAddBtn = false;
        //keep the rest and do nothing else.
        return;
      }
      let data = await staffService.query([{ uuId: staffId}], ['CONTRACT', 'COMPANY', 'USER',
        'DEPARTMENT', 'LOCATION', 'SKILL', 'RESOURCE', 'STORAGE_FILE', 'NOTE', 'TAG'])
      .then(response => {
        return response.data[response.data.jobCase] || [];
      })
      .catch(e => {
        if (e != null && e.response != null && e.response.status == 403) {
          this.isAccessDenied = true;
          return [];
        }
        this.handleHttpAjaxError(e);
        return [];
      });

      if (data.length > 0) {
        this.digestStaffResponse(data[0]);
      }
      this.disableContractAddBtn = false;
    },
    httpAjaxError(e) {
      const response = e.response;
      if (response && 403 === response.status) {
        this.alertMsg = this.$t('error.authorize_action');
        this.alertError = true;
        
      } else if (response && 422 === response.status) {
        const feedback = response.data[response.data.jobCase][0];
        if(feedback.spot) {
          this.alertMsg = this.$t('error.attention_required');
          this.alertError = true;
          this.errors.add({
            field: `staff.${feedback.spot}`,
            msg: this.$t(`error.${feedback.clue}`, feedback.args)
          })
        } else {
          this.alertMsg = this.$t('error.internal_server');
          this.alertError = true;
        }
      } else {
        this.alertMsg = this.$t('error.internal_server');
        this.alertError = true;
      }
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.componentId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.querySelector('.alert') : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    },
    dismissAlert() {
      this.alertMsg = null;
      this.alertError = true;
    },
    resetProperties() {
      this.errors.clear();
      this.$validator.reset();
      const keys = Object.keys(this.staff);
      for(let i = 0, len = keys.length; i < len; i++) {
        if(keys[i] === 'uuId') {
          continue;
        }
        else if (keys[i] === 'payAmount') {
          this.staff[keys[i]] = 0;
        }
        else if (keys[i] === 'websites') {
          this.staff[keys[i]] = [null];
        }
        else if (keys[i] === 'phones' || keys[i] === 'socials') {
          this.staff[keys[i]] = [{ kind: null, data: null }];
        }
        else {
          let customField = this.customFields.find(f => f.name === keys[i])
          if (customField) {
            if (customField.def) {
              this.staff[keys[i]] = customField.def;
              continue;
            }
          }
          this.staff[keys[i]] = null;
        }
      }
      this.staff.payFrequency = 'Annually';
      this.originSkills = [];
      this.originDeps = [];
      this.originResources = [];
      this.originLocId = null;
      this.avatarBanner.avatarId = null;
      this.avatarBanner.bannerId = null;
      this.departments =[];
      this.depPreselected = null;
      this.skills = [],
      this.toConfirmSkills = [],
      this.skillLevelEdit.uuId = null;
      this.skillLevelEdit.name = null;
      this.skillLevelEdit.level = null;
      this.resources = [],
      this.toConfirmResources = [],
      this.resourceUnitEdit.uuId = null;
      this.resourceUnitEdit.name = null;
      this.resourceUnitEdit.unit = null;
      this.resourceUnitEdit.utilization = null;
      this.originCompany = [];
      const company = this.$store.state.company;
      if (company) {
        this.company = [company];
        if (company.locationList && company.locationList.length > 0) {
          this.location = [{ uuId: company.locationList[0].uuId, name: company.locationList[0].name }];
        }
        this.staff.color = company.color;
      }
      else {
        this.company = [];
        this.location = [];
      }
      this.files = [];
      this.originEmail = null;
      this.originUserEmail = null;
      this.user = null;
      this.originStaff = null;
    },
    skillBadgeRemove: function(index) {
      this.skills.splice(index,1)
    },
    skillBadgeClick(id) {
      const selected = this.skills.find(i => i.uuId === id);
      const edit = this.skillLevelEdit;
      edit.uuId = selected.uuId;
      edit.name = selected.name;
      edit.level = selected.level;
      edit.data = selected;
      this.state.skillLevelEditShow = true;
    },
    skillSelectorToggle() {
      this.state.skillSelectorShow = true;
    },
    skillSelectorOk({ details }) {
      const newSkills = details.map(i => { return { uuId: i.uuId, name: i.name, level: null, color: i.color, show: false }});
      this.toConfirmSkills.splice(0, this.toConfirmSkills.length, ...newSkills);
      for(let i = this.toConfirmSkills.length-1; i > -1; i--) {
        this.toConfirmSkills[i].toBeShown = true;
      }
      this.toConfirmSkills[this.toConfirmSkills.length - 1].toBeShown = false;
      this.toConfirmSkills[this.toConfirmSkills.length - 1].show = true;
    },
    skillLevelOk({ id, name, level, oldId, customFields, customData }) {
      const oldIdIndex = oldId? this.skills.findIndex(i => i.uuId === oldId) : -1;
      let selected = null;
      if(oldIdIndex != -1) {
        selected= this.skills[oldIdIndex];
      } else {
        selected = this.skills.find(i => i.uuId === id);
      }
      selected.uuId = id;
      selected.name = name;
      selected.level = level;  
      for (const field of customFields) {
        selected[field.name] = customData[field.name];
      }
    },
    toConfirmSkillOk({ id, name, level, oldId, applyToAll, customFields, customData }) {
      const oldIdIndex = oldId? this.toConfirmSkills.findIndex(i => i.uuId === oldId) : -1;
      let selected = null;
      if(oldIdIndex != -1) {
        selected = this.toConfirmSkills[oldIdIndex];
        selected.uuId = id;
        selected.name = name;
      } else {
        selected = this.toConfirmSkills.find(i => i.uuId === id);
      }
     
      selected.level = level;
      selected.customFields = customFields;
      selected.customData = customData;
      const toBeShown = this.toConfirmSkills.filter(i => i.toBeShown);
      
      if (selected.color) {
        this.staff.color = selected.color;
      }
      
      if(toBeShown.length === 0) {
        this.toConfirmSkillCommit();
      } 
      else if (applyToAll) {
        for (const entry of toBeShown) {
          entry.toBeShown = false;
          entry.level = level;
          entry.customFields = customFields;
          entry.customData = customData;
        }
        this.toConfirmSkillCommit();
      }
      else {
        toBeShown[toBeShown.length - 1].toBeShown = false;
        toBeShown[toBeShown.length - 1].show = true;
      }
    },
    toConfirmSkillCancel() {
      this.toConfirmSkillCommit();
    },
    toConfirmSkillCommit() {
      //Set all show state to false. To close/hide remaining modals if any.
      for(let i = 0, len = this.toConfirmSkills.length; i < len; i ++) {
        this.toConfirmSkills[i].show = false;
      }
      const confirmedSkills = this.toConfirmSkills.filter(i => i.level && i.level.length > 0);
      const ids = confirmedSkills.map(i => i.uuId);
      const currentIds = this.skills.map(i => i.uuId);
      const duplicatedIds = currentIds.filter(i => ids.includes(i));
      const newSkills = confirmedSkills.filter(i => !currentIds.includes(i.uuId));

      let skill = null;
      for(let i = 0, len = this.skills.length; i < len; i++) {
        skill = this.skills[i];
        if(duplicatedIds.includes(skill.uuId)) {
          const confirmed = confirmedSkills.find(i => i.uuId === skill.uuId);
          skill.level = confirmed.level;
          for (const field of confirmed.customFields) {
            skill[field.name] = confirmed.customData[field.name];
          }
        }
      }

      newSkills.forEach(i => {
        const skill = {uuId: i.uuId, name: i.name, level: i.level};
        
        for (const field of i.customFields) {
          skill[field.name] = i.customData[field.name];
        }
        this.skills.push( skill );
      });
    },
    resourceBadgeRemove: function(index) {
      this.resources.splice(index,1)
    },
    resourceBadgeClick(id) {
      const selected = this.resources.find(i => i.uuId === id);
      const edit = this.resourceUnitEdit;
      edit.uuId = selected.uuId;
      edit.name = selected.name;
      edit.unit = selected.quantity;
      edit.utilization = selected.utilization;
      edit.data = selected;
      this.state.resourceUnitEditShow = true;
    },
    resourceSelectorToggle() {
      this.state.resourceSelectorShow = true;
    },
    resourceSelectorOk({ details }) {
      const newResources = details.map(i => { return { uuId: i.uuId, name: i.name, unit: null, show: false }});
      this.toConfirmResources.splice(0, this.toConfirmResources.length, ...newResources);
      for(let i = this.toConfirmResources.length-1; i > -1; i--) {
        this.toConfirmResources[i].toBeShown = true;
      }
      this.toConfirmResources[this.toConfirmResources.length - 1].toBeShown = false;
      this.toConfirmResources[this.toConfirmResources.length - 1].show = true;
    },
    resourceUnitOk({ id, name, unit, utilization, oldId, customFields, customData }) {
      const oldIdIndex = oldId? this.resources.findIndex(i => i.uuId === oldId) : -1;
      let selected = null;
      if(oldIdIndex != -1) {
        selected= this.resources[oldIdIndex];
      } 
      else {
        selected = this.resources.find(i => i.uuId === id);
      }
      selected.uuId = id;
      selected.name = name;
      selected.quantity = unit;
      selected.utilization = utilization;
      for (const field of customFields) {
        selected[field.name] = customData[field.name];
      }
    },
    toConfirmResourceOk({ id, name, unit, utilization, oldId, applyToAll, customFields, customData }) {
      const oldIdIndex = oldId? this.toConfirmResources.findIndex(i => i.uuId === oldId) : -1;
      let selected = null;
      if(oldIdIndex != -1) {
        selected = this.toConfirmResources[oldIdIndex];
        selected.uuId = id;
        selected.name = name;
      } else {
        selected = this.toConfirmResources.find(i => i.uuId === id);
      }
      
      selected.unit = unit;
      selected.utilization = utilization;
      selected.customFields = customFields;
      selected.customData = customData;
      
      const toBeShown = this.toConfirmResources.filter(i => i.toBeShown);
      
      if(toBeShown.length === 0) {
        this.toConfirmResourceCommit();
      } 
      else if (applyToAll) {
        for (const entry of toBeShown) {
          entry.toBeShown = false;
          entry.unit = unit;
          entry.utilization = utilization;
          entry.customFields = customFields;
          entry.customData = customData;
        }
        this.toConfirmResourceCommit();
      }
      else {
        toBeShown[toBeShown.length - 1].toBeShown = false;
        toBeShown[toBeShown.length - 1].show = true;
      }
    },
    toConfirmResourceCancel() {
      this.toConfirmResourceCommit();
    },
    toConfirmResourceCommit() {
      //Set all show state to false. To close/hide remaining modals if any.
      for(let i = 0, len = this.toConfirmResources.length; i < len; i ++) {
        this.toConfirmResources[i].show = false;
      }
      const confirmedResources = this.toConfirmResources.filter(i => i.unit && i.unit > 0);
      const ids = confirmedResources.map(i => i.uuId);
      const currentIds = this.resources.map(i => i.uuId);
      const duplicatedIds = currentIds.filter(i => ids.includes(i));
      const newResources = confirmedResources.filter(i => !currentIds.includes(i.uuId));

      let resource = null;
      for(let i = 0, len = this.resources.length; i < len; i++) {
        resource = this.resources[i];
        if(duplicatedIds.includes(resource.uuId)) {
          const confirmed = confirmedResources.find(i => i.uuId === resource.uuId);
          resource.quantity = confirmed.unit;
          resource.utilization = confirmed.utilization;
              
          for (const field of confirmed.customFields) {
            resource[field.name] = confirmed.customData[field.name];
          }
        }
      }

      newResources.forEach(i => {
        const res = {uuId: i.uuId, name: i.name, quantity: i.unit, utilization: i.utilization};
      
        for (const field of i.customFields) {
          res[field.name] = i.customData[field.name];
        }
        this.resources.push( res );
      });
    },
    depSelectorToggle() {
      this.depPreselected = null;
      this.state.depSelectorShow = true;
    },
    depSelectorOk({details}) {
      const departments = JSON.parse(JSON.stringify(this.departments));
      
      if (details.length > 0) {
        const newDepartmentsToReplacePreselected = [];
        for (const r of details) {
          const foundIndex = departments.findIndex(i => i.uuId == r.uuId);
          if (foundIndex > -1) {
            const department = departments[foundIndex];
            department.uuId = r.uuId;
            department.name = r.name;
            newDepartmentsToReplacePreselected.push(department);
            departments.splice(foundIndex, 1);
          } else {
            newDepartmentsToReplacePreselected.push({ uuId: r.uuId, name: r.name })
          }
        }

        if (this.depPreselected != null) {
          const foundIndex = departments.findIndex(i => i.uuId == this.depPreselected);
          if (foundIndex != -1) {
            departments.splice(foundIndex, 1, ...newDepartmentsToReplacePreselected);
          } else {
            departments.push(...newDepartmentsToReplacePreselected);
          }
        } else {
          departments.push(...newDepartmentsToReplacePreselected);
        }
      } else if (this.depPreselected != null) {
        //When no selection is made, remove the preselected one from the existing departments
        departments.splice(departments.findIndex(j => j.uuId === this.depPreselected), 1);
      }
      this.departments.splice(0, this.departments.length, ...departments);

      this.depPreselected = null;
    },
    depBadgeRemove: function(index) {
      this.departments.splice(index,1)
    },
    depBadgeClick(id) {
      this.depPreselected = this.departments.find(i => i.uuId === id).uuId;
      this.state.depSelectorShow = true;
    },
    locSelectorOpen() {
      this.locationEditId = null;
      this.state.locSelectorShow = true;
    },
    locSelectorOk({ details }) {
      if (details != null && details.length > 0) {
        this.location = [{ uuId: details[0].uuId, name: details[0].name }];
      }

      this.locationEditId = null;
    },
    // locSelectorOk({ details }) {
    //   this.location = [{ uuId: details[0].uuId, name: details[0].name }];
    // },
    avatarBannerStatus({ alertMsg }) {
      if(alertMsg) {
        this.alertMsg = alertMsg;
        this.alertError = true;
      }
    },
    showCalendar() {
      if(this.id && this.id.indexOf('STAFF_NEW_') !== -1) {
        this.alertMsg = "Please save staff before editing calendar";
        this.alertError = true;
        setTimeout(() => {
          this.scrollToTop();
        }, 0);
        return;
      }
      
      const self = this;
      staffService.calendar(this.contractStaffId != null ? this.contractStaffId : this.id)
      .then((response) => {
        // combine the calendar lists into single lists for each day
        const data = response.data[response.data.jobCase];
        self.calendar = processCalendar(data);
        self.state.calShow = true;
      })
      .catch((e) => {
        self.httpAjaxError(e);
      });
    },
    calOk() {
    
    },
    confirmEmailChangeOk() {
      this.state.confirmEmailChangeShow = false;
      this.ok();
    },
    preOk() {
      if(this.originUserEmail && this.originUserEmail === this.originEmail && this.originEmail !== this.staff.email) {
        this.state.confirmEmailChangeShow = true;
      } else {
        this.ok();
      }
    },
    fileSelectorToggle() {
      this.state.fileSelectorShow = true;
    },
    fileSelectorOk(details) {
      const currentIds = this.files.map(i => i.uuId);
      const newFiles = details.filter(i => !currentIds.includes(i.uuId));

      for(const c of newFiles) {
        this.files.push( {uuId: c.uuId, name: c.name, type: c.type} );
      }
    },
    downloadCancel() {
      if(this.downloadCancelTokenSource) {
        this.downloadCancelled = true;
        this.downloadCancelTokenSource.cancel();
      }
    },
    fileBadgeRemove: function(index) {
      this.files.splice(index,1);
    },
    fileBadgeClick(item) {
      // file download
      this.downloadProgressShow = true;
      forceFileDownload([{ uuId: item.uuId, name: labelFilename(item.name, item.type), type: item.type }], this);
    },
    removeUnchangedStaffProperties(data) {
      //Remove those properties whose value is not changed in provided data against original staff.
      //Assuming all properties are string type.
      //Property with data type other than string needs dedicated comparison logic.
      const originalStaff = this.originStaff;
      const keys = Object.keys(data).filter(i => i != 'uuId');
      let hasChanged = false;
      const potKey = ['socials', 'phones'];

      const hasArrayChanged = (oValue, cValue) => {
        if (oValue == null && cValue == null) {
          return false;
        }
        if (oValue == null || cValue == null) {
          return true;
        }
        return JSON.stringify(oValue) != JSON.stringify(cValue);
      }

      for (const key of keys) {
        if (originalStaff[key] === data[key]) {
          delete data[key];
          continue;
        } else if ('websites' == key || potKey.includes(key)) {
          if (!hasArrayChanged(originalStaff[key], data[key])) {
            delete data[key];
            continue;
          }
        }
        if (!hasChanged) {
          hasChanged = true;
        }
      }
      return hasChanged;
    },
    locationBadgeRemove: function(index) {
      this.location.splice(index,1);
    },
    locationBadgeClick(id) {
      this.locationEditId = id;
      this.state.locSelectorShow = true;
    },
    addNote() {
      this.note = {
        text: null,
        identifier: null
      }
      this.state.noteShow = true;
    },
    editNote(id) {
      const found = this.notes.find(i => i.uuId == id);
      if (found != null) {
        this.note = cloneDeep(found);
        this.state.noteShow = true;
      } else {
        this.alertMsg = this.$t('unable_to_open_detail', ['entityType.NOTE']);
      }
    },
    removeNote(id) {
      const index = this.notes.findIndex(i => i.uuId == id);
      if (index != -1) {
        this.notes.splice(index, 1);
      }
    },
    toAddNote(payload) {
      payload.uuId = `NEW_NOTE_${strRandom(5)}`;
      this.notes.unshift(payload);
    },
    toUpdateNote(payload) {
      const found = this.notes.find(i => i.uuId == payload.uuId);
      if (found != null) {
        for (const key of Object.keys(payload)) {
          found[key] = payload[key];
        }
      }
    },
    tagsModified({tags}) {
      this.tags = tags;
    },
    quotaAddMinus(delta) {
      if(isNaN(this.staff.resourceQuota) || (typeof this.staff.resourceQuota == 'string' && this.staff.resourceQuota.trim().length == 0)) {
        this.staff.resourceQuota = this.minQuotaValue;
        return;
      }
      const value = parseInt(this.staff.resourceQuota) + delta;
      if (value >= this.minQuotaValue && value <= this.maxQuotaValue) {
        this.staff.resourceQuota = value;
      }
    },
    onQuotaBlur() {
      if(isNaN(this.staff.resourceQuota) || (typeof this.staff.resourceQuota == 'string' && this.staff.resourceQuota.trim().length == 0)) {
        this.staff.resourceQuota = this.minQuotaValue;
      } else {
        const value = parseInt(this.staff.resourceQuota);
        if (value < this.minQuotaValue) {
          this.staff.resourceQuota = this.minQuotaValue;  
        } else if (value > this.maxQuotaValue) {
          this.staff.resourceQuota = this.maxQuotaValue;  
        }
      }
    },
    onStaffContractChanged() {
      this.triggeredByContractStaffChanged = true;
    },
    async createContractStaff(data) {
      const selectedContract = this.contracts.find(i => i.uuId == data.uuId);
      const referenceId = data.uuId.substring(data.uuId.indexOf('CONTRACT_STAFF_NEW_') + 'CONTRACT_STAFF_NEW_'.length);
      delete data.uuId;

      //Override/Set links to empty lists. Link creaction will not be handled here.
      data.locationList = [];
      data.tagList = [];
      data.skillList = [];
      data.storageFileList = [];
      data.departmentList = [];
      data.resourceList = [];
      
      //noteList is not required and will not be cloned
      delete data.noteList;

      //Set companyList value
      if (Array.isArray(this.company) && this.company.length > 0) {
        data.companyList = JSON.parse(JSON.stringify(this.company));
      } else {
        data.companyList = [this.$store.state.company];
      }

      const result = {
        hasError: false,
        msg: this.$t('staff.create')
      }
      removeDeniedProperties(this.permissionName, data, 'ADD');
      let staffId = await staffService.clone(referenceId, data, { asContract: true })
      .then(response => {
        const data = response.data;
        return data.jobClue.uuId;
      }).catch(e => {
        result.hasError = true;
        result.msg = this.$t(`staff.error.failed_to_create_staff`);
        if (e.response && 403 === e.response.status) {
          result.msg = this.$t('error.authorize_action');
        } else if(e.response && 422 == e.response.status) {
          const list = e.response.data[e.response.data.jobCase];
          let hasFieldError = false;
          let hasEdgeAlreadyExisted = false;
          const clues = ['Missing_argument', 'Invalid_value', 'Not_unique_key']
          for(let i = 0, len = list.length; i < len; i++) {
            if('Already_have_edge' === list[i].clue) {
              hasEdgeAlreadyExisted = true;
              break;
            }
            if(clues.includes(list[i].clue)) {
              let spot = list[i].args || [];
              spot = spot.length > 0? spot[0] : '';
              spot = spot.length == 1? spot.toLowerCase() : spot.length > 1? spot.charAt(0).toLowerCase() + spot.substring(1) : spot;
              this.errors.add({
                field: `staff.${spot}`,
                msg: this.$t(`staff.error.${this.isGeneric && list[i].clue === 'Not_unique_key' ? 'generic_not_unique_key': list[i].clue.toLowerCase()}`, [this.$t(`staff.field.${spot}`)])
              });
              hasFieldError = true;
            }
          }
          if(hasEdgeAlreadyExisted) {
            result.hasError = false;
            return data.uuId;
          } else if(hasFieldError) {
            result.msg = this.$t(`error.attention_required`);
          }
        }
      });
      if (result.hasError) {
        return result;
      }

      result.staffId = staffId;
      if (selectedContract) {
        this.$set(selectedContract, 'uuId', staffId);
        this.$set(selectedContract, 'name', `${data.firstName != null? data.firstName + ' ' : ''}${data.lastName != null? data.lastName : ''}`);
        this.$set(selectedContract, 'startDate', data.startDate);
        this.$set(selectedContract, 'endDate', data.endDate);
        this.$set(selectedContract, 'position', data.position);
        if (data.companyList[0].name) {
          this.$set(selectedContract, 'company', data.companyList[0].name);
        }
        this.contracts.sort(this.contractSort);
        this.previousContractStaffId = staffId;
        
      }
      return result;
    },
    contractStaffAdd() {
      if (this.disableContractAddBtn) {
        return;
      }
      this.disableContractAddBtn = true; //to prevent multiple clicks in a short time
      let newContractStaffId = null;
      if (this.contractStaffId == null) {
        newContractStaffId = `CONTRACT_STAFF_NEW_${this.id}`;
      } else {
        const index = this.contractStaffId.indexOf('CONTRACT_STAFF_NEW_');
        if (index == -1) {
          newContractStaffId = `CONTRACT_STAFF_NEW_${this.contractStaffId}`;
        } else {
          const referenceId = this.contractStaffId.substring(this.contractStaffId.indexOf('CONTRACT_STAFF_NEW_') + 'CONTRACT_STAFF_NEW_'.length);
          newContractStaffId = `${strRandom(5)}_CONTRACT_STAFF_NEW_${referenceId}`;
        }
      }

      this.contracts.unshift({ uuId: newContractStaffId });

      this.triggeredByContractStaffChanged = true;
      this.contractStaffId = newContractStaffId;
    },
    contractStaffRemove() {
      if (this.disableContractRemoveBtn == true) {
        return;
      }
      if (this.contractStaffId == null) {
        return;
      }
      this.state.confirmRemoveContractShow = true;
    },
    hasNonGenericDataChanged() {
      const data = cloneDeep(this.staff);
      delete data.resourceQuota;
      if(data.websites != null) {
        data.websites = data.websites.filter(i => i != null && i.length > 0);
      }
      if(data.socials != null) {
        data.socials = data.socials.filter(i => i != null && i.kind != null && i.data != null && i.data.length > 0);
      }
      if(data.phones != null) {
        data.phones = data.phones.filter(i => i != null && i.kind != null && i.data != null && i.data.length > 0);
      }
      data['avatarRef'] = this.avatarBanner.avatarId;
      data['bannerRef'] = this.avatarBanner.bannerId;
      if (data['avatarRef'] == null) {
        data['avatarRef'] = '00000000-0000-0000-0000-000000000000'
      }
      if (data['bannerRef'] == null) {
        data['bannerRef'] = '00000000-0000-0000-0000-000000000000'
      }

      if(data['startDate']) {
        if (typeof data['startDate'] !== 'string') {
          data['startDate'] = moment(`${data['startDate'].getFullYear()}-${data['startDate'].getMonth() + 1}-${data['startDate'].getDate()}T${data['startDate'].getHours()}:${data['startDate'].getMinutes()}:${data['startDate'].getSeconds()}`, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
        }
      } else {
        data['startDate'] = this.DATE_START_AS_NULL;
      }

      if(data['endDate']) {
        if (typeof data['endDate'] !== 'string') {
          data['endDate'] = moment(`${data['endDate'].getFullYear()}-${data['endDate'].getMonth() + 1}-${data['endDate'].getDate()}T${data['endDate'].getHours()}:${data['endDate'].getMinutes()}:${data['endDate'].getSeconds()}`, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
        }
      } else {
        data['endDate'] = this.DATE_END_AS_NULL;
      }
      let hasChanged = this.removeUnchangedStaffProperties(data);
      if (hasChanged) {
        return true;
      }
      //location
      hasChanged = this.hasLocationChanged();
      if (hasChanged) {
        return true;
      }
      //company
      hasChanged = this.hasCompanyChanged();
      if (hasChanged) {
        return true;
      }
      //department
      hasChanged = this.hasDepartmentChanged();
      if (hasChanged) {
        return true;
      }
      //skill
      hasChanged = this.hasSkillChanged();
      if (hasChanged) {
        return true;
      }
      //resource
      hasChanged = this.hasResourceChanged();
      if (hasChanged) {
        return true;
      }
      hasChanged = this.hasFileChanged();
      if (hasChanged) {
        return true;
      }
      //tag
      hasChanged = this.hasTagChanged();
      if (hasChanged) {
        return true;
      }
      //note
      hasChanged = this.hasNoteChanged();
      if (hasChanged) {
        return true;
      }
      return false;
    },
    confirmSaveBeforeContractOk(toSave=false) {
      this.state.saveBeforeContractStaffShow = false;
      if (toSave) {
        //set flag, staffSubmit() will call staffGet() after saving successfully.
        this.saveTriggeredByContractStaffChanged = true;
        this.staffSubmit();
      } else {
        if (this.previousContractStaffId != null && this.previousContractStaffId.includes('CONTRACT_STAFF_NEW_')) {
          const foundIndex = this.contracts.findIndex(i => i.uuId == this.previousContractStaffId);
          if (foundIndex > -1) {
            this.contracts.splice(foundIndex, 1);
          }
          this.previousContractStaffId = this.previousContractStaffId.substring(this.previousContractStaffId.indexOf('CONTRACT_STAFF_NEW_') + 'CONTRACT_STAFF_NEW_'.length);
        }
        //Proceed to load the contract staff
        this.staffGet(this.contractStaffId);
      }
    },
    confirmSaveBeforeContractCancel() {
      this.notToUpdatePrevContractStaffId = true;
      this.contractStaffId = this.previousContractStaffId;
      this.disableContractAddBtn = false;
    },
    getContractOptionLabel(opt, index) {
      if (opt == null || (
            opt.startDate == null && opt.endDate == null
              && (opt.position == null || opt.position.trim().length == 0)
              && opt.name == null && opt.company == null)
          ) {
        return this.$t('staff.default_contract_option_label', [index+1]);
      } else {
        let label = '';
        if (opt.startDate || opt.startDate) {
          label = `${opt.startDate && opt.startDate != '1970-01-01'? opt.startDate + ' - ' : ''}${opt.endDate && opt.endDate != '3000-01-01'? opt.endDate : ''}`;
        }
        if (opt.position != null && opt.position.trim().length > 0) {
          if (label.length > 0) {
            label = `${label}, ${opt.position}`;
          } else {
            label = opt.position;
          }
        }
        
        //Use company if available. 
        if (opt.company != null && opt.company.trim().length > 0) {
          if (label.length > 0) {
            label = `${label}, ${opt.company}`;
          } else {
            label = opt.company;
          }
        } 
        
        return label;
      }
    },
    async confirmRemoveContractOk() {
      this.state.confirmRemoveContractShow = false;
      const foundIndex = this.contracts.findIndex(i => i.uuId == this.contractStaffId);
      if (foundIndex > -1) {
        if (!this.contractStaffId.includes('CONTRACT_STAFF_NEW_')) {
          const hasError = await staffService.remove([{ uuId: this.contractStaffId }])
          .then(() => {
            return false;
          })
          .catch(() => {
            return true;
          });
          if (hasError) {
            this.alertMsg = this.$t('staff.error.failed_to_delete_contract');
            return;
          }
        }
        
        this.contracts.splice(foundIndex, 1);
        if (this.contracts.length > 0) {
          this.notToUpdatePrevContractStaffId = true;
          this.contractStaffId = this.contracts[0].uuId;
          //Proceed to load the contract staff
          this.staffGet(this.contractStaffId);
        } else {
          //close the staffmodal.
          this.$emit('success', { msg: this.$t('staff.delete') });
          this.$emit('update:show', false);
        }
        this.$emit('refresh-list');
      }
    },
    contractSort(a, b) {
      if (a == null || (a.uuId != null && a.uuId.includes('CONTRACT_STAFF_NEW_'))) {
        return -1;
      }
      if (b == null || (b.uuId != null && b.uuId.includes('CONTRACT_STAFF_NEW_'))) {
        return -1;
      }
      const aStartDate = a.startDate != null ? a.startDate : '1970-01-01';
      const bStartDate = b.startDate != null ? b.startDate : '1970-01-01';
      const aEndDate = a.endDate != null ? a.endDate : '3000-01-01';
      const bEndDate = b.endDate != null ? b.endDate : '3000-01-01';
      
      if (aStartDate != bStartDate) {
        return aStartDate < bStartDate? -1 : 1;
      } else if (aEndDate != bEndDate) {
        return aEndDate < bEndDate? -1 : 1;
      } else {
        0
      }
    },
    hasLocationChanged() {
      //Prerequisite: No more than one location is allowed per staff.
      const oldId = this.originLocId;
      const newId = this.location.length > 0? this.location[0].uuId : null;
      return oldId != newId;
    },
    hasCompanyChanged() {
      //Prerequisite: No more than one company is allowed per staff.
      const oldId = this.originCompany.length > 0? this.originCompany[0].uuId: null;
      const newId = this.company.length > 0? this.company[0].uuId: null;
      return oldId != newId;
    },
    hasDepartmentChanged() {
      if (this.originDeps.length != this.departments.length) {
        return true;
      }
      const oldIds = this.originDeps.length > 0? this.originDeps.map(i => i.uuId) : [];
      const newIds = this.departments.length > 0? this.departments.map(i => i.uuId) : [];
      let hasChanged = false;
      for(const id of newIds) {
        if (!oldIds.includes(id)) {
          hasChanged = true;
          break;
        }
      }
      return hasChanged;
    },
    getObjPropsInString(obj) {
      if (obj == null) {
        return null;
      }
      const tokens = [];
      for(const prop in obj) {
        tokens.push(`${obj[prop]}`);
      }
      return tokens.join('|');
    },
    hasSkillChanged() {
      if (this.originSkills.length != this.skills.length) {
        return true;
      }
      const oldStrings = this.originSkills.length > 0? this.originSkills.map(i => this.getObjPropsInString(i)) : [];
      const newStrings = this.skills.length > 0? this.skills.map(i => this.getObjPropsInString(i)) : [];
      let hasChanged = false;
      for(const str of newStrings) {
        if (!oldStrings.includes(str)) {
          hasChanged = true;
          break;
        }
      }
      return hasChanged;
    },
    hasFileChanged() {
      if (this.originFiles.length != this.files.length) {
        return true;
      }
      const oldIds = this.originFiles.length > 0? this.originFiles.map(i => i.uuId) : [];
      const newIds = this.files.length > 0? this.files.map(i => i.uuId) : [];
      let hasChanged = false;
      for(const id of newIds) {
        if (!oldIds.includes(id)) {
          hasChanged = true;
          break;
        }
      }
      return hasChanged;
    },
    hasResourceChanged() {
      if (this.originResources.length != this.resources.length) {
        return true;
      }
      const oldStrings = this.originResources.length > 0? this.originResources.map(i => this.getObjPropsInString(i)) : [];
      const newStrings = this.resources.length > 0? this.resources.map(i => this.getObjPropsInString(i)) : [];
      let hasChanged = false;
      for(const str of newStrings) {
        if (!oldStrings.includes(str)) {
          hasChanged = true;
          break;
        }
      }
      return hasChanged;
    },
    hasTagChanged() {
      //this.originTags, this.tags
      if (this.originTags.length != this.tags.length) {
        return true;
      }
      if (this.tags.some(i => i.uuId == null)) {
        return true;
      }
      const oldIds = this.originTags.length > 0? this.originTags.map(i => i.uuId) : [];
      const newIds = this.tags.length > 0? this.tags.map(i => i.uuId) : [];
      let hasChanged = false;
      for(const id of newIds) {
        if (!oldIds.includes(id)) {
          hasChanged = true;
          break;
        }
      }
      return hasChanged;
    },
    getNotePropsInString(obj) {
      if (obj == null) {
        return null;
      }
      const tokens = [];
      for(const prop in obj) {
        if (prop !== 'author' &&
            prop !== 'authorRef' &&
            prop !== 'modified' &&
            prop !== 'created') {
          tokens.push(`${obj[prop]}`);
        }
      }
      return tokens.join('|');
    },
    hasNoteChanged() {
      //this.originNotes, notes
      if (this.originNotes.length != this.notes.length) {
        return true;
      }
      if (this.notes.some(i => i.uuId == null || i.uuId.startsWith('NEW_NOTE'))) {
        return true;
      }
      const oldStrings = this.originNotes.length > 0? this.originNotes.map(i => this.getNotePropsInString(i)) : [];
      const newStrings = this.notes.length > 0? this.notes.map(i => this.getNotePropsInString(i)) : [];
      let hasChanged = false;
      for(const str of newStrings) {
        if (!oldStrings.includes(str)) {
          hasChanged = true;
          break;
        }
      }
      return hasChanged;
    },
    allowViewFunc(fieldName) {
      return this.canView(this.permissionName, [fieldName]) 
              && ((!this.exists && this.canAdd(this.permissionName, [fieldName]) || this.exists));
    }
  }
}
</script>

<style lang="scss" scoped>

.user-buttons {
  flex: 1 1 auto;
}

.greyed {
  color: var(--text-disabled);
}

.color-container {
  position: relative;
}
</style>
